import React, { useState, useEffect } from "react";

// import { useDispatch } from "react-redux";
import img1 from "../assets/Capture.jpeg";

import AddComments from "./AddComments";
import ReplyCard from "./ReplyCard";

const CommentsCard = (props) => {
  //   const dispatch = useDispatch();
  const { comment } = props;
  const [visiblty, setVisiblty] = useState("none");
  const [repVisiblty, setRepVisiblty] = useState("none");
  const [commentID, setCommentID] = useState("");

  function toggle(msg) {
    setCommentID(msg);
    if (visiblty === "none") {
      setVisiblty("block");
    } else {
      setVisiblty("none");
    }
  }

  function viewReplies() {
    if (repVisiblty === "none") {
      setRepVisiblty("block");
    } else {
      setRepVisiblty("none");
    }
  }

  return (
    <>
      <div className="all-comment">
        <div className="row">
          {/* <Col xs={24} sm={12} lg={2}> */}
          <div className="col-xl-1 col-lg-1">
            <img src={img1}></img>
          </div>
          {/* </Col> */}
          {/* <Col xs={24} sm={12} lg={21}> */}
          <div className="add-comment-area col-xl-10 col-lg-10">
            <div className="u-detail">
              <p className="u-name">
                {comment?.mentor ? comment?.mentor?.name : comment?.user?.name}
              </p>
              <p className="u-date">1 day ago</p>
            </div>
            <div className="comment-text">
              <p>{comment?.message}</p>
            </div>
            {comment.replies.length ? (
              <div className="viewReplies">
                <p
                  style={{ paddingRight: "10px", cursor: "pointer" }}
                  onClick={() => viewReplies()}
                >
                  View Replies
                </p>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => toggle(comment?._id)}
                >
                  Reply
                </p>
              </div>
            ) : (
              <div className="viewReplies">
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => toggle(comment?._id)}
                >
                  Reply
                </p>
              </div>
            )}
            <div className="reply" style={{ display: visiblty }}>
              <AddComments type="reply" id={commentID} />
            </div>

            {comment.replies.length &&
              comment.replies.map((comment) => (
                <div className="replyCard" style={{ display: repVisiblty }}>
                  <ReplyCard comment={comment} />
                </div>
              ))}
            {/* </Col> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentsCard;
