import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../../layout/Layout";

const AccountDetails = () => {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  return (
    <Layout>
      <ToastContainer />
      <section className="contact-form-area ">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6">
              <div className="contact-form-wrapper text-center mb-30">
                <h2 className="mb-45">Account Details</h2>
                <form
                  className="row gx-3 comments-form contact-form"
                  //   onSubmit={handleSubmit}
                >
                  <div className="col-lg-12 mb-30">
                    <input
                      type="text"
                      name="name"
                      placeholder="Username"
                      value={inputs.name || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-12 mb-30">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={inputs.email || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-12 mb-30">
                    <input
                      type="number"
                      name="number"
                      placeholder="Phone Number"
                      value={inputs.number || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-12 mb-30">
                    <input
                      type="number"
                      name="age"
                      placeholder="Age"
                      value={inputs.age || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-12 mb-30">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={inputs.password || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-12 mb-30">
                    <input
                      type="password"
                      name="password2"
                      placeholder="Confirm Password"
                      value={inputs.password2 || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <button className="theme_btn message_btn mt-20">
                    {loading ? (
                      <div class="spinner-border text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      'Update'
                    )}
                  </button>
                </form>
             
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AccountDetails;
