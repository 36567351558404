import React, { useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AccountDetails from "./pages/Account/AccountDetails";
import Signup from "./pages/Auth/Signup";
import Categories from "./pages/Categories/Categories";
import CheckoutForm from "./pages/Checkout/CheckoutForm";
import CourseDetails from "./pages/Course/CourseDetails";
import Home from "./pages/Home/Home";
import About from "./pages/Information/About";
import Contact from "./pages/Information/Contact";
import FAQ from "./pages/Information/FAQ";
import InstructorProfile from "./pages/Instructors/InstructorProfile";
import Instructors from "./pages/Instructors/Instructors";
import Registration from "./pages/Mentor/Registration";
import Results from "./pages/SearchResults";
import Learning from "./pages/Learning";
import Material from "./pages/Material";
import Quiz from "./pages/Quiz";
import Player from "./pages/Player";

const Router = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      "token"
    )}`;
  }, []);

  return (
    <BrowserRouter>
      {/* <ScrollToTop> */}
      {/* <ScrollToTop> */}{" "}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mentor" element={<Registration />} />
        <Route path="/course-details/:id" element={<CourseDetails />} />
        <Route path="/auth" element={<Signup />} />
        {/* <Route path="/register" element={<Signup />} /> */}
        <Route path="/account" element={<AccountDetails />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/search/:id" element={<Results />} />
        <Route path="/learning" element={<Learning />} />
        <Route path="/instructors" element={<Instructors />} />
        <Route path="/instructors/:id" element={<InstructorProfile />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/material/:id" element={<Material />} />
        <Route path="/quiz/:id" element={<Quiz />} />
        <Route path="/player" element={<Player />} />
      </Routes>
      {/* </ScrollToTop> */}
      {/* </ScrollToTop> */}
    </BrowserRouter>
  );
};

export default Router;
