import React, { useRef } from "react";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import Layout from "../../layout/Layout";
import { useSwiper } from "swiper/react";
import Swal from "sweetalert2";

import "./mentor.css";

// swiper css
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useState } from "react";
import { useEffect } from "react";
import { GetQuestions, PostAnswers } from "../../services/mentor";
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const swiper = useSwiper();
  const navigate= useNavigate()
  const [formData, setFormData] = useState({});
  const [hasCompleted, setHasCompleted] = useState(false);
  const [questions, setQuestions] = useState(null);
  const inputReference = useRef(null);


  const handleInputChange = (e) => {
    console.log(e.target.name);
  };
  const handleSubmit =async () => {
    try {
      let answers = []
      for(let [key, value] of Object.entries(formData)){
        if(value === ""){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please fill all the fields",
          });
          return;
        }
        answers.push({questionId: key, answer: value})
      }
     
      let response = await PostAnswers(answers);
      Swal.fire({
        title: "Request Submitted",
        text: response?.message,
        icon: "success",
      })
      console.log(response)
      navigate('/')
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
   

  }
  // components
  const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper();
    swiper.on("reachEnd", function () {
      console.log("reach to End");
      setHasCompleted(true);
    });
    
    return (
      <div className="d-flex justify-content-center mt-20">
        <button
          className="theme_btn border_btn justify-content-center"
          onClick={() =>
            hasCompleted
              ? handleSubmit()
              : swiper.slideNext()
          }
        >
          {hasCompleted ? "Submit" : "Next"}
        </button>
      </div>
    );
  };
  const FormSection = ({ title, name, placeholder, value }) => {
    return (
      <div className="d-flex justify-content-center">
        <section className="subscribe-area ">
          <div className="container">
            <div className="row justify-content-center">
              <div>
                <div className="subscribe-wrapper text-center pb-30">
                  <h3>{title}</h3>
                  <div className=" pos-rel">
                    <div className="subscribe-form mt-30 ">
                      <input
                        type="text"
                        value={formData[name] || ''}
                        ref={inputReference}
                        placeholder={placeholder}
                        name={name}
                        // onChange={(e) => handleInputChange(e)}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };
  const fetchQuestions = async () => {
    const result = await GetQuestions();
    console.log(result)
    setQuestions(result);
  };
  useEffect(() => {
    window.scrollTo(0,0)
    fetchQuestions();
    inputReference?.current?.focus();
  }, []);
  return (
    <Layout>
      <div className={"regForm"}>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={(e) => console.log("slide change", e)}
         
          onSwiper={(swiper) =>
            swiper.activeIndex === 2
              ? setHasCompleted(true)
              : setHasCompleted(false)
          }
          allowTouchMove={false}
        >
          <SwiperButtonNext >
            {hasCompleted ? "Submit" : "Next"}
          </SwiperButtonNext>

          {questions?.map((question, i) => (
            <SwiperSlide>
              {FormSection({
                title: question?.ques,
                name: question?._id,
                placeholder: question?.ques,
                value: { formData },
              })}
            </SwiperSlide>
          ))}
          {/* <SwiperSlide>
            {FormSection({
              title: "Hello What's Your Name ?",
              name: "name",
              placeholder: "Write Your Full Name",
              value: { formData },
            })}
          </SwiperSlide>
          <SwiperSlide>
            {FormSection({
              title: "What's Your Email Address ?",
              name: "email",
              placeholder: "Your Email Address",
              value: { formData },
            })}
          </SwiperSlide>
          <SwiperSlide>
            {FormSection({
              title: "What's Your Phone Number ?",
              name: "number",
              placeholder: "Your Phone Number",
              value: { formData },
            })}
          </SwiperSlide> */}
        </Swiper>
      </div>
    </Layout>
  );
};

export default Registration;
