import Image1 from '../assets/digitz-logo.png';
import Image2 from '../assets/mindshare-logo.png';
import Image3 from '../assets/publicis-logo-removebg-preview.png';
import Image4 from '../assets/starcom-logo.png';
import Image5 from '../assets/UM_Logo-removebg-preview.png';
import Image6 from '../assets/OMD_Logo-removebg-preview.png';
import Image7 from '../assets/Google.png';
import Image8 from '../assets/meta.png';


const Brands = () => {
    return(
        <div className='brands-container'>
            <div className='brands-heading'>
                <h2>Experts</h2>
            </div>
            <div className='brands-text'>
                <p>from top brands & companies are mentoring at EDME</p>
            </div>
            <div className='brands-marque'>
            <marquee width="100%" direction="left" height="auto">
                <img src={Image1} />
                <img src={Image2} />
                <img src={Image3} />
                <img src={Image4} />
                <img src={Image5} />
                <img src={Image6} />
                <img src={Image7} />
                <img src={Image8} />
            </marquee>
            </div>

        </div>
    )
}
export default Brands;