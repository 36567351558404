import React from "react";
import Layout from "../../layout/Layout";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import CourseCard from "../../components/CourseCard";
import { GetCourses, getCategories, getCourseByCategory } from "../../services/courses";
import loadjs from "loadjs";
import { useLocation } from "react-router-dom";
import SubscribeForm from "../../components/SubscribeForm";
// import ExpertsCarousel from "../../components/ExpertsCarousel";
import ExpertsComp from "../../components/ExpertsComp";
import Overview from "../../components/Overview";
import Brands from "../../components/Brands";
import Home1 from "../../assets/images/Home_01.png";
import Home2 from "../../assets/images/Home_02.png";
import Home3 from "../../assets/images/Home_03.png";
import Blog1 from "../../assets/images/Blogs_&_News_01.png";
import Blog2 from "../../assets/images/Blogs_&_News_02.png";
import Blog3 from "../../assets/images/Blogs_&_News_03.png";
import Instructor1 from "../../assets/images/Explore-Experienced-Instructor_01.png";
import Instructor2 from "../../assets/images/Explore-Experienced-Instructor_02.png";
import Instructor3 from "../../assets/images/Explore-Experienced-Instructor_03.png";
import Instructor4 from "../../assets/images/Explore-Experienced-Instructor_04.png";
import Instructor5 from "../../assets/images/Explore-Experienced-Instructor_05.png";
import Instructor6 from "../../assets/images/Explore-Experienced-Instructor_06.png";
import Instructor7 from "../../assets/images/Explore-Experienced-Instructor_07.png";
import Instructor8 from "../../assets/images/Explore-Experienced-Instructor_08.png";
import Instructor9 from "../../assets/images/Explore-Experienced-Instructor_09.png";
import ExpertsCarousel from "../../components/ExpertsCarousel";

const Home = () => {
  const [courses, setCourses] = useState(null);
  const [inputSearch, setInputSearch] = useState("");
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const fetchCourses = async () => {
    let data = await GetCourses();
    setCourses(data);
  };
  const fetchCategories = async () => {
    let data = await getCategories();
    setCategories(data);
  };
  const handleFilter = async (id) => {
    let data = await getCourseByCategory(id);
    setCourses(data);
  };
  const searchCont = async (e) => {
    // setInputSearch()
    setInputSearch(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCourses();
    fetchCategories();

  }, []);
  useEffect(() => {
    loadjs(
      [
        "/assets/js/vendor/modernizr-3.5.0.min.js",
        "/assets/js/vendor/jquery-2.2.4.min.js",
        "/assets/js/popper.min.js",
        "/assets/js/bootstrap.min.js",
        "/assets/js/owl.carousel.min.js",
        "/assets/js/isotope.pkgd.min.js",
        "/assets/js/slick.min.js",
        "/assets/js/metisMenu.min.js",
        "/assets/js/jquery.nice-select.js",
        "/assets/js/ajax-form.js",
        "/assets/js/wow.min.js",
        "/assets/js/jquery.counterup.min.js",
        "/assets/js/waypoints.min.js",
        "/assets/js/jquery.scrollUp.min.js",
        "/assets/js/imagesloaded.pkgd.min.js",
        "/assets/js/jquery.magnific-popup.min.js",
        "/assets/js/jquery.easypiechart.js",
        "/assets/js/plugins.js",
        "/assets/js/main.js",
      ],
      () => {
        console.log("loadjs loaded");
      }
    );
  }, [location?.pathname]);
  return (
    <Layout>
      {/* <div id="preloader">
        <div className="preloader">
          <span></span>
          <span></span>
        </div>
      </div> */}
      <main>
        {/* <!--slider-area start--> */}
        <section className="slider-area ">
          <img
            className="sl-shape shape_01"
            src="assets/img/icon/01.svg"
            alt=""
          />
          <img
            className="sl-shape shape_02"
            src="assets/img/icon/02.svg"
            alt=""
          />
          <img
            className="sl-shape shape_03"
            src="assets/img/icon/03.svg"
            alt=""
          />
          <img
            className="sl-shape shape_04"
            src="assets/img/icon/04.svg"
            alt=""
          />
          <img
            className="sl-shape shape_05"
            src="assets/img/icon/05.svg"
            alt=""
          />
          <img
            className="sl-shape shape_06"
            src="assets/img/icon/06.svg"
            alt=""
          />
          <img
            className="sl-shape shape_07"
            src="assets/img/shape/dot-box-5.svg"
            alt=""
          />
          <div className="main-slider pt-10">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6 order-last order-lg-first">
                  <div className="slider__img__box mb-50 pr-30">
                    <img
                      className="img-one mt-55 pr-70"
                      src={Home1}
                      // src="assets/img/slider/01.png"
                      alt=""
                    />
                    <img className="slide-shape img-two" src={Home2} alt="" />
                    <img className="slide-shape img-three" src={Home3} alt="" />
                    <img
                      className="slide-shape img-four"
                      src="assets/img/shape/dot-box-1.svg"
                      alt=""
                    />
                    <img
                      className="slide-shape img-five"
                      src="assets/img/shape/dot-box-2.svg"
                      alt=""
                    />
                    <img
                      className="slide-shape img-six"
                      src="assets/img/shape/zigzg-1.svg"
                      alt=""
                    />
                    <img
                      className="slide-shape img-seven wow fadeInRight animated"
                      data-delay="1.5s"
                      src="assets/img/icon/dot-plan-1.svg"
                      alt="Chose-img"
                    />
                    <img
                      className="slide-shape img-eight"
                      src="assets/img/slider/earth-bg.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="slider__content pt-15">
                    <h1
                      className="main-title mb-40 wow fadeInUp2 animated"
                      data-wow-delay=".1s"
                    >
                      Learn Everyday & Any New Skills Online with Top{" "}
                      <span className="vec-shape">Experts.</span>
                    </h1>
                    <h5
                      className="mb-35 wow fadeInUp2 animated"
                      data-wow-delay=".2s"
                    >
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered altera /tion in
                      some form.
                    </h5>
                    <ul className="search__area d-md-inline-flex align-items-center justify-content-between mb-30">
                      <li>
                        <div className="widget__search">
                          <form className="input-form" action="#">
                            <input
                              onChange={searchCont}
                              type="text"
                              placeholder="Find Courses"
                            />
                          </form>
                          <button className="search-icon">
                            <i className="far fa-search"></i>
                          </button>
                        </div>
                      </li>
                      {/* <li>
                        <div className="widget__select">
                          <select
                            className="nice-select"
                            name="select-cat"
                            id="select"
                          >
                            <option value="">Categories</option>
                            <option value="">Class One</option>
                            <option value="">Class Two</option>
                            <option value="">Class Three</option>
                            <option value="">Class Four</option>
                            <option value="">Class Five</option>
                          </select>
                        </div>
                      </li> */}
                      <li>
                        <Link to={inputSearch ? "/search/" + inputSearch : "/"}>
                          <button className="theme_btn search_btn ml-35">
                            Search Now
                          </button>
                        </Link>
                      </li>
                    </ul>
                    <p className="highlight-text">
                      <span>#1</span> Worldwide Online Learning & Skills
                      Development Platform
                    </p>
                    {/* {localStorage.getItem("token") && (
                      <button
                        className="theme_btn search_btn ml-35"
                        style={{
                          height: "max-content",
                          width: "max-content",
                          padding: "10px 20px",
                        }}
                        onClick={() => {
                          window.open("http://edme.media:82/login", "_blank");
                        }}
                      >
                        Student portal
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--slider-area end-->
       <!--great-deal-area start--> */}
        <section className="great-deal-area pt-150 pb-90 pt-md-100 pb-md-20 pt-xs-100 pb-xs-40">
          <div className="container">
            <div className="row justify-content-lg-center justify-content-start">
              <div className="col-xl-3 col-lg-8">
                <div className="deal-box mb-30 text-center text-xl-start">
                  <h2 className="mb-20">
                    <b>Great</b> Deals For You
                  </h2>
                  <p>
                    There are many variations of passa of Lorem Ipsum available,
                    but the majority have suffered.
                  </p>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="deal-active owl-carousel mb-30">
                  <div className="single-item">
                    <div className="single-box mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/puzzle.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Learn New Skills</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box2 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/manager.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Expert Trainers</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box3 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/notepad.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Free Trial Lesson</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/puzzle.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Learn New Skills</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box2 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/manager.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Expert Trainers</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--great-deal-area end-->
       <!--what-loking-for start--> */}
        {true && (
          <section className="what-looking-for pos-rel">
            <div className="what-blur-shape-one"></div>
            <div className="what-blur-shape-two"></div>
            <div className="what-look-bg gradient-bg pt-70 pb-60  pb-md-80 pt-xs-95 pb-xs-80">
              <div className="container">
                {/* <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="section-title text-center mb-55">
                      <h5 className="bottom-line mb-25">Teachers & Students</h5>
                      <h2>What you Looking For?</h2>
                    </div>
                  </div>
                </div> */}
                {/* <div className="row mb-85">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div
                      className="what-box text-center mb-35 wow fadeInUp2 animated"
                      data-wow-delay=".3s"
                    >
                      <div className="what-box__icon mb-30">
                        <img src="assets/img/icon/phone-operator.svg" alt="" />
                      </div>
                      <h3>Do you want to teach here?</h3>
                      
                      <Link to="/mentor" className="theme_btn border_btn">
                        Register Now
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div
                      className="what-box text-center mb-35 wow fadeInUp2 animated"
                      data-wow-delay=".3s"
                    >
                      <div className="what-box__icon mb-30">
                        <img src="assets/img/icon/graduate.svg" alt="" />
                      </div>
                      <h3>Do you want to learn here?</h3>
                      
                      <a href="auth" className="theme_btn border_btn active">
                        Register Now
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="categoris-container">
                  <div className="col-xl-12">
                    <div className="section-title text-center mb-55">
                      <h5 className="bottom-line mb-25">Browse Categories</h5>
                      <h2>Explore our Online Subjects</h2>
                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-5">
                    <div className="col">
                      <div
                        className="single-category text-center mb-30 wow fadeInUp2 animated"
                        data-wow-delay=".1s"
                      >
                        <img
                          className="mb-30"
                          src="assets/img/category-icon/atom.svg"
                          alt=""
                        />
                        <h4 className="sub-title mb-10">
                          <Link to="/categories">Media Planning</Link>
                        </h4>
                        <p>126+ Courses Available</p>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="single-category text-center mb-30 wow fadeInUp2 animated"
                        data-wow-delay=".2s"
                      >
                        <img
                          className="mb-30"
                          src="assets/img/category-icon/web-development.svg"
                          alt=""
                        />
                        <h4 className="sub-title mb-10">
                          <Link to="/categories">Programmatic</Link>
                        </h4>
                        <p>325+ Courses Available</p>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="single-category text-center mb-30 wow fadeInUp2 animated"
                        data-wow-delay=".3s"
                      >
                        <img
                          className="mb-30"
                          src="assets/img/category-icon/atom.svg"
                          alt=""
                        />
                        <h4 className="sub-title mb-10">
                          <Link to="/categories">Performance </Link>
                        </h4>
                        <p>95+ Courses Available</p>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="single-category text-center mb-30 wow fadeInUp2 animated"
                        data-wow-delay=".4s"
                      >
                        <img
                          className="mb-30"
                          src="assets/img/category-icon/career-path.svg"
                          alt=""
                        />
                        <h4 className="sub-title mb-10">
                          <Link to="/categories">Strategy</Link>
                        </h4>
                        <p>156+ Courses Available</p>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="single-category text-center mb-30 wow fadeInUp2 animated"
                        data-wow-delay=".5s"
                      >
                        <img
                          className="mb-30"
                          src="assets/img/category-icon/graphic-tool.svg"
                          alt=""
                        />
                        <h4 className="sub-title mb-10">
                          <Link to="/categories">SEO/SEM</Link>
                        </h4>
                        <p>136+ Courses Available</p>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div
                      className="col-md-12 mt-20 text-center mb-20 wow fadeInUp2 animated"
                      data-wow-delay=".6s"
                    >
                      <Link to="/categories" className="theme_btn">
                        All Categories
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* <!--what-loking-for end-->
        <!-- case-gallery start --> */}
        <section className="feature-course pt-70 pb-60 pt-md-95 pb-md-80 pt-xs-95 pb-xs-80">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="section-title text-center mb-50">
                  <h5 className="bottom-line mb-25">Featured Courses</h5>
                  <h2>Explore our Popular Courses</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-12 text-center">
              <div className="portfolio-menu mb-60">
                  <button
                    className="gf_btn active"
                    onClick={() => handleFilter("all")}
                  >
                    All
                  </button>
                  |
                  {categories?.map((category, index) => (
                    <button
                      className="gf_btn"
                      data-filter={`.${category?.name}`}
                      onClick={() => handleFilter(category?._id)}
                    >
                      {category?.name}
                    </button>
                  ))}
                  {/* <button className="gf_btn" data-filter=".cat1">
                    Career
                  </button>
                  <button className="gf_btn" data-filter=".cat2">
                    Development
                  </button>
                  <button className="gf_btn" data-filter=".cat3">
                    Business
                  </button>
                  <button className="gf_btn" data-filter=".cat4">
                    science
                  </button>
                  <button className="gf_btn" data-filter=".cat5">
                    Design
                  </button> */}
                </div>
              </div>
            </div>
            {!courses ? (
              <div
                className="d-flex justify-content-center align-items-center mb-40"
                style={{
                  minHeight: "100%",
                }}
              >
                <div
                  className="spinner-border "
                  role="status"
                  style={{
                    minHeight: "100%",
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="row"
                style={{
                  // minHeight: "100%",
                  height: "100% !important",
                }}
              >
                {courses?.length > 0 ? (
                  courses?.map((course, index) => (
                    <CourseCard role="public" course={course} />
                  ))
                ) : (
                  <div
                    className="col-xxl-12 mt-20 text-center mb-20"
                    style={{
                      minHeight: "100%",
                    }}
                  >
                    <h2>No Courses Found</h2>
                  </div>
                )}
              </div>
            )}
            <div className="row">
              <div
                className="col-lg-12 mt-20 text-center mb-20 wow fadeInUp2 animated"
                data-wow-delay=".3s"
              >
                <Link to="/categories" className="theme_btn">
                  All Categories
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- case-gallery end -->
        <!-- why-chose-section-wrapper start --> */}
        <div className="why-chose-section-wrapper gradient-bg mr-100 ml-100">
          {/* <!-- why-chose-us start --> */}
          <section className="why-chose-us">
            <div className="why-chose-us-bg pt-70 pb-105 pt-md-95 pb-md-90 pt-xs-95 pb-xs-90">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xl-7 col-lg-7">
                    <div className="chose-img-wrapper mb-50 pos-rel">
                      <div className="coures-member">
                        <h5>Total Students</h5>
                        <img
                          className="choses chose_01"
                          src="assets/img/chose/01.png"
                          alt="Chose-img"
                        />
                        <img
                          className="choses chose_02"
                          src="assets/img/chose/02.png"
                          alt="Chose-img"
                        />
                        <img
                          className="choses chose_03"
                          src="assets/img/chose/03.png"
                          alt="Chose-img"
                        />
                        <img
                          className="choses chose_04"
                          src="assets/img/chose/04.png"
                          alt="Chose-img"
                        />
                        <span>25k+</span>
                      </div>
                      <div className="feature tag_01">
                        <span>
                          <img src="assets/img/icon/shield-check.svg" alt="" />
                        </span>{" "}
                        Safe & Secured
                      </div>
                      <div className="feature tag_02">
                        <span>
                          <img src="assets/img/icon/catalog.svg" alt="" />
                        </span>{" "}
                        120+ Catalog
                      </div>
                      <div className="feature tag_03">
                        <span>
                          <i className="fal fa-check"></i>
                        </span>{" "}
                        Quality Education
                      </div>
                      <div className="video-wrapper">
                        <a
                          href="https://www.youtube.com/watch?v=7omGYwdcS04"
                          className="popup-video"
                        >
                          <i className="fas fa-play"></i>
                        </a>
                      </div>
                      <div className="img-bg pos-rel">
                        <img
                          className="chose_05 pl-70 pl-lg-0 pl-md-0 pl-xs-0"
                          src="assets/img/chose/05.png"
                          alt="Chose-img"
                        />
                      </div>
                      <img
                        className="chose chose_06"
                        src="assets/img/shape/dot-box3.svg"
                        alt="Chose-img"
                      />
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-5">
                    <div className="chose-wrapper pl-25 pl-lg-0 pl-md-0 pl-xs-0">
                      <div
                        className="section-title mb-30 wow fadeInUp2 animated"
                        data-wow-delay=".1s"
                      >
                        <h5 className="bottom-line mb-25">Explore EDME</h5>
                        <h2 className="mb-25">Why Choose EDME?</h2>
                        <p>
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration
                          in some form. There are many variations of passages of
                          Lorem Ipsum available.
                        </p>
                      </div>
                      <ul
                        className="text-list mb-40 wow fadeInUp2 animated"
                        data-wow-delay=".2s"
                      >
                        <li>
                          There are many variations of passages of Lorem Ipsum.
                        </li>
                        <li>
                          The majority have suffered alteration in some form.{" "}
                        </li>
                        <li>
                          There are many variations of passages of Lorem Ipsum.
                        </li>
                      </ul>
                      <Link
                        to="about"
                        className="theme_btn wow fadeInUp2 animated"
                        data-wow-delay=".3s"
                      >
                        More Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- why-chose-us end -->
            <!-- course-instructor start --> */}
          {/* <section className="course-instructor nav-style-two pos-rel">
            <div className="course-blur-shape"></div>
            <div className="course-bg-space pt-50 pb-70 pt-md-95 pb-md-70 pt-xs-95 pb-xs-70">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-9">
                    <div className="section-title text-center text-md-start mb-60">
                      <h5 className="bottom-line mb-25">Our Instructor</h5>
                      <h2 className="mb-25">Explore Experienced Instructor</h2>
                    </div>
                  </div>
                </div>
                <div className="instructor-active owl-carousel">
                  <div className="item">
                    <div className="z-instructors text-center mb-30">
                      <div className="z-instructors__thumb mb-15">
                        <img src={Instructor1} alt="" />
                        <div className="social-media">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </div>
                      </div>
                      <div className="z-instructors__content">
                        <h4 className="sub-title mb-10">
                          <a href="instructors/3">Yasir Ahmed</a>
                        </h4>
                        <p>Software Development</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="z-instructors text-center mb-30">
                      <div className="z-instructors__thumb mb-15">
                        <img src={Instructor2} alt="" />
                        <div className="social-media">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </div>
                      </div>
                      <div className="z-instructors__content">
                        <h4 className="sub-title mb-10">
                          <a href="instructors/22">Usama Hashmi</a>
                        </h4>
                        <p>UI/UX Designer</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="z-instructors text-center mb-30">
                      <div className="z-instructors__thumb mb-15">
                        <img src={Instructor3} alt="" />
                        <div className="social-media">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </div>
                      </div>
                      <div className="z-instructors__content">
                        <h4 className="sub-title mb-10">
                          <a href="instructor-profile">Sana</a>
                        </h4>
                        <p>Programmer</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="z-instructors text-center mb-30">
                      <div className="z-instructors__thumb mb-15">
                        <img src={Instructor4} alt="" />
                        <div className="social-media">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </div>
                      </div>
                      <div className="z-instructors__content">
                        <h4 className="sub-title mb-10">
                          <a href="instructor-profile">Hina Saleem</a>
                        </h4>
                        <p>Digital Marketing</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="z-instructors text-center mb-30">
                      <div className="z-instructors__thumb mb-15">
                        <img src={Instructor5} alt="" />
                        <div className="social-media">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                          <a href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </div>
                      </div>
                      <div className="z-instructors__content">
                        <h4 className="sub-title mb-10">
                          <a href="instructors/3">Khizram Saeed</a>
                        </h4>
                        <p>Software Development</p>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </section> */}
          {/* <!-- course-instructor end --> */}
        </div>
        {/* <!-- why-chose-section-wrapper start --> */}
        {/* <!-- testimonial-area start --> */}
        <div className="container">
          <ExpertsCarousel />
        </div>
        <section className="testimonial-area testimonial-pad pt-70 pb-120 pt-md-95 pb-md-70 pt-xs-95 pb-xs-70">
          <div className="container custom-container-testimonial">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="section-title text-center text-md-start mb-35">
                  <h5 className="bottom-line mb-25">Our Instructor</h5>
                  <h2 className="mb-25">Explore Experienced Instructor</h2>
                </div>
              </div>
              <div className="col-lg-4 text-center text-lg-end">
                <Link to="/instructors" className="theme_btn">
                  Read All Reviews
                </Link>
              </div>
            </div>
            <div className="row testimonial-active-01">
              <div className="col-xl-3">
                <div className="item">
                  <div className="testimonial-wrapper mb-30">
                    <div className="testimonial-authors overflow-hidden mb-15">
                      <div className="testimonial-authors__avatar">
                        <img src={Instructor6} alt="testi-author" />
                      </div>
                      <div className="testimonial-authors__content mt-10">
                        <h4 className="sub-title">Elijah</h4>
                        <p>Full Stack Developer</p>
                      </div>
                    </div>
                    <p>
                      " Lorem ipsum dolor sit amet, consetetur sadip scing
                      elitr, sed di nonumy eirmod tempor invidt utlabore et
                      dolore magn aliq erat.
                    </p>
                    <div className="quote-icon mt-20">
                      <img src="assets/img/icon/quote.svg" alt="quote-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <div className="testimonial-wrapper mb-30">
                    <div className="testimonial-authors overflow-hidden mb-15">
                      <div className="testimonial-authors__avatar">
                        <img src={Instructor7} alt="testi-author" />
                      </div>
                      <div className="testimonial-authors__content mt-10">
                        <h4 className="sub-title">Emily Gemon</h4>
                        <p>User Interface</p>
                      </div>
                    </div>
                    <p>
                      " Lorem ipsum dolor sit amet, consetetur sadip scing
                      elitr, sed di nonumy eirmod tempor invidt utlabore et
                      dolore magn aliq erat.
                    </p>
                    <div className="quote-icon mt-20">
                      <img src="assets/img/icon/quote.svg" alt="quote-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <div className="testimonial-wrapper mb-30">
                    <div className="testimonial-authors overflow-hidden mb-15">
                      <div className="testimonial-authors__avatar">
                        <img src={Instructor8} alt="testi-author" />
                      </div>
                      <div className="testimonial-authors__content mt-10">
                        <h4 className="sub-title">Micheal George</h4>
                        <p>Content Writer</p>
                      </div>
                    </div>
                    <p>
                      " Lorem ipsum dolor sit amet, consetetur sadip scing
                      elitr, sed di nonumy eirmod tempor invidt utlabore et
                      dolore magn aliq erat.
                    </p>
                    <div className="quote-icon mt-20">
                      <img src="assets/img/icon/quote.svg" alt="quote-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <div className="testimonial-wrapper mb-30">
                    <div className="testimonial-authors overflow-hidden mb-15">
                      <div className="testimonial-authors__avatar">
                        <img src={Instructor9} alt="testi-author" />
                      </div>
                      <div className="testimonial-authors__content mt-10">
                        <h4 className="sub-title">Lucas</h4>
                        <p>Full Stack Developer</p>
                      </div>
                    </div>
                    <p>
                      " Lorem ipsum dolor sit amet, consetetur sadip scing
                      elitr, sed di nonumy eirmod tempor invidt utlabore et
                      dolore magn aliq erat.
                    </p>
                    <div className="quote-icon mt-20">
                      <img src="assets/img/icon/quote.svg" alt="quote-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- testimonial-area end -->
        <!-- blog-area start --> */}
        <section className="blog-area mr-100 ml-100" id="latestNews">
          <div className="blog-bg gradient-bg pl-100 pr-100 pt-70 pb-120  pb-md-70 pt-xs-100 pb-xs-70">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="section-title text-center mb-60">
                    <h5 className="bottom-line mb-25">Latest News</h5>
                    <h2 className="mb-25">Lots of new Blogs & News</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div
                    className="z-blogs mb-30 wow fadeInUp2 animated"
                    data-wow-delay=".1s"
                  >
                    <div className="z-blogs__thumb mb-30">
                      {/* <a href="blog-details">
                      </a> */}
                      <img src={Blog1} alt="blog-img" />
                    </div>
                    <div className="z-blogs__content">
                      <h5 className="mb-25">Online . School . Skill</h5>
                      <h4 className="sub-title mb-15">
                        {/* <a href="blog-details">
                        </a> */}
                        5 Ways to Use Padlet in Higher -Ed Online Classroom
                      </h4>
                      <div className="z-blogs__meta d-sm-flex justify-content-between pt-20">
                        <span>Date : June 15 , 2021</span>
                        <span>By Guest Admin</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div
                    className="z-blogs mb-30 wow fadeInUp2 animated"
                    data-wow-delay=".2s"
                  >
                    <div className="z-blogs__thumb mb-30">
                      {/* <a href="blog-details">
                      </a> */}
                      <img src={Blog2} alt="blog-img" />
                    </div>
                    <div className="z-blogs__content">
                      <h5 className="mb-25">Education . Expert</h5>
                      <h4 className="sub-title mb-15">
                        {/* <a href="blog-details">
                        </a> */}
                        4 New Zoom Features Enhance Virtual Teaching & Learning
                      </h4>
                      <div className="z-blogs__meta d-sm-flex justify-content-between pt-20">
                        <span>Date : June 15 , 2021</span>
                        <span>By Guest Admin</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div
                    className="z-blogs mb-30 wow fadeInUp2 animated"
                    data-wow-delay=".3s"
                  >
                    <div className="z-blogs__thumb mb-30">
                      {/* <a href="blog-details">
                      </a> */}
                      <img src={Blog3} alt="blog-img" />
                    </div>
                    <div className="z-blogs__content">
                      <h5 className="mb-25">Creative . Programmer</h5>
                      <h4 className="sub-title mb-15">
                        {/* <a href="blog-details">
                        </a> */}
                        Using Social Media to Support Online Teaching
                      </h4>
                      <div className="z-blogs__meta d-sm-flex justify-content-between pt-20">
                        <span>Date : June 15 , 2021</span>
                        <span>By Guest Admin</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div
                  className="col-lg-12 text-center mt-20 mb-30 wow fadeInUp2 animated"
                  data-wow-delay=".4s"
                >
                  <a href="blog-details" className="theme_btn">
                    Load More
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* <ExpertsComp /> */}

        <Overview />
        <Brands />
        <section className="what-looking-for pos-rel">
          <div className="what-blur-shape-one"></div>
          <div className="what-blur-shape-two"></div>
          <div className="what-look-bg pt-70 gradient-bg pt-xs-95 pb-xs-80">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="section-title text-center mb-55">
                    <h5 className="bottom-line mb-25">Teachers & Students</h5>
                    <h2>What you Looking For?</h2>
                  </div>
                </div>
              </div>
              <div className="row mb-85">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="what-box text-center mb-35 wow fadeInUp2 animated"
                    data-wow-delay=".3s"
                  >
                    <div className="what-box__icon mb-30">
                      <img src="assets/img/icon/phone-operator.svg" alt="" />
                    </div>
                    <h3>Do you want to teach here?</h3>
                    {/* <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed di nonumy eirmod tempor invidunt ut labore et dolore
                        magn aliq erat.
                      </p> */}
                    <Link to="/mentor" className="theme_btn border_btn">
                      Register Now
                    </Link>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="what-box text-center mb-35 wow fadeInUp2 animated"
                    data-wow-delay=".3s"
                  >
                    <div className="what-box__icon mb-30">
                      <img src="assets/img/icon/graduate.svg" alt="" />
                    </div>
                    <h3>Do you want to learn here?</h3>
                    {/* <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed di nonumy eirmod tempor invidunt ut labore et dolore
                        magn aliq erat.
                      </p> */}
                    <Link to="/auth" className="theme_btn border_btn active">
                      Register Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- blog-area end -->
        <!-- subscribe-area start --> */}
        <SubscribeForm />

        {/* <!-- subscribe-area end --> */}
      </main>
    </Layout>
  );
};

export default Home;
