import React from "react";
import { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { GetSearchedCourses } from "../services/courses";
import CourseCard from "../components/CourseCard";
import loadjs from "loadjs";
import SubscribeForm from "../components/SubscribeForm";
import { useParams } from "react-router-dom";

const SearchResults = () => {
  const [courses, setCourses] = useState(null);
  const param = useParams();
  const fetchCourses = async () => {
    let data = await GetSearchedCourses(param.id);
    setCourses(data);
  };
  useEffect(() => {
    // console.log(param.id)
    fetchCourses();
  }, []);

  return (
    <Layout>
      <main>
        <section
          className="page-title-area d-flex align-items-end "
          style={{ backgroundImage: "url('assets/img/page-title-bg/01.jpg')" }}
        >
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="page-title-wrapper mb-50">
                  <h1 className="page-title mb-25">Search Results</h1>
                  {/* <div className="breadcrumb-list">
                    <ul className="breadcrumb">
                      <li>
                        <a href="index.html">Home - Pages -</a>
                      </li>
                      <li>
                        <a href="#">Categories</a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--page-title-area end--> */}
        {/* <!--great-deal-area start--> */}
        {/* <section className="great-deal-area pt-150 pb-90 pt-md-90 pb-md-40 pt-xs-100 pb-xs-40">
          <div className="container">
            <div className="row justify-content-lg-center justify-content-start">
              <div className="col-xl-3 col-lg-8">
                <div className="deal-box mb-30 text-center text-xl-start">
                  <h2 className="mb-20">
                    <b>Great</b> Deals For You
                  </h2>
                  <p>
                    There are many variations of passa of Lorem Ipsum available,
                    but the majority have suffered.
                  </p>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="deal-active owl-carousel mb-30">
                  <div className="single-item">
                    <div className="single-box mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/puzzle.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Learn New Skills</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box2 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/manager.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Expert Trainers</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box3 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/notepad.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Free Trial Lesson</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/puzzle.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Learn New Skills</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box2 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/manager.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Expert Trainers</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!--great-deal-area end--> */}
        {/* <!-- feature-course start --> */}
        <section className="feature-course pt-150 pb-130 pt-md-95 pb-md-80 pt-xs-95 pb-xs-80">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="section-title text-center mb-30">
                  {/* <h5 className="bottom-line mb-25">Featured Courses</h5> */}
                  <h2>Explore our Popular Courses</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-12 text-center">
                <div className="portfolio-menu mb-60">
                  <button className="gf_btn active" data-filter="*">
                    All
                  </button>
                  <button className="gf_btn" data-filter=".cat1">
                    Career
                  </button>
                  <button className="gf_btn" data-filter=".cat2">
                    Development
                  </button>
                  <button className="gf_btn" data-filter=".cat3">
                    Business
                  </button>
                  <button className="gf_btn" data-filter=".cat4">
                    science
                  </button>
                  <button className="gf_btn" data-filter=".cat5">
                    Design
                  </button>
                </div>
              </div>
            </div>
            {!courses?.length ? (
              <div className="d-flex justify-content-center align-items-center mb-40">
                <div className="spinner-border " role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="grid row">
                {courses?.map((course, index) => (
                  <CourseCard role="public" course={course} />
                ))}
                {/* {!courses && (
                <div className="spinner-border d-flex justify-content-center align-items-center" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )} */}
                {/* <Link
                to={"/course-details"}
                className="col-lg-4 col-md-6 grid-item cat2 cat3"
              >
                <div className="z-gallery mb-30">
                  <div className="z-gallery__thumb mb-20">
                    <img
                      className="img-fluid"
                      src="assets/img/course/01.png"
                      alt=""
                    />

                    <div className="feedback-tag">4.8(256)</div>
                    <div className="heart-icon">
                      <i className="fas fa-heart"></i>
                    </div>
                  </div>
                  <div className="z-gallery__content">
                    <div className="course__tag mb-15">
                      <span>Science</span>
                      <span>Lab</span>
                      <a className="f-right" href="instructor-details">
                        <img
                          className="rounded-circle"
                          src="assets/img/course/in1.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <h4 className="sub-title mb-20">
                      <Link to="/course-details">
                        Take Your Career to the Next Level Future
                      </Link>
                    </h4>
                    <div className="course__meta">
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/time.svg"
                          alt="course-meta"
                        />{" "}
                        12 Class
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/bar-chart.svg"
                          alt="course-meta"
                        />{" "}
                        Higher
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/user.svg"
                          alt="course-meta"
                        />{" "}
                        6395+
                      </span>
                      <span>$260</span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={"/course-details"} className="col-lg-4 col-md-6 grid-item cat1 cat3 cat4">
                <div className="z-gallery mb-30">
                  <div className="z-gallery__thumb mb-20">
                    <Link to="/course-details">
                      <img
                        className="img-fluid"
                        src="assets/img/course/02.png"
                        alt=""
                      />
                    </Link>
                    <div className="feedback-tag">4.8(256)</div>
                    <div className="heart-icon">
                      <i className="fas fa-heart"></i>
                    </div>
                  </div>
                  <div className="z-gallery__content">
                    <div className="course__tag mb-15">
                      <span>Science</span>
                      <span>Lab</span>
                      <a className="f-right" href="instructor-details">
                        <img
                          className="rounded-circle"
                          src="assets/img/course/in3.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <h4 className="sub-title mb-20">
                      <Link to="/course-details">
                        Your Career to build for the pro level
                      </Link>
                    </h4>
                    <div className="course__meta">
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/time.svg"
                          alt="course-meta"
                        />{" "}
                        12 Class
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/bar-chart.svg"
                          alt="course-meta"
                        />{" "}
                        Higher
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/user.svg"
                          alt="course-meta"
                        />{" "}
                        6395+
                      </span>
                      <span>$260</span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={"/course-details"} className="col-lg-4 col-md-6 grid-item cat2 cat1 cat5">
                <div className="z-gallery mb-30">
                  <div className="z-gallery__thumb mb-20">
                    <Link to="/course-details">
                      <img
                        className="img-fluid"
                        src="assets/img/course/03.png"
                        alt=""
                      />
                    </Link>
                    <div className="feedback-tag">4.8(256)</div>
                    <div className="heart-icon">
                      <i className="fas fa-heart"></i>
                    </div>
                  </div>
                  <div className="z-gallery__content">
                    <div className="course__tag mb-15">
                      <span>Science</span>
                      <span>Lab</span>
                      <a className="f-right" href="instructor-details">
                        <img
                          className="rounded-circle"
                          src="assets/img/course/in1.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <h4 className="sub-title mb-20">
                      <Link to="/course-details">
                        Take A Course For You Biright Future
                      </Link>
                    </h4>
                    <div className="course__meta">
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/time.svg"
                          alt="course-meta"
                        />{" "}
                        12 Class
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/bar-chart.svg"
                          alt="course-meta"
                        />{" "}
                        Higher
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/user.svg"
                          alt="course-meta"
                        />{" "}
                        6395+
                      </span>
                      <span>$260</span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={"/course-details"} className="col-lg-4 col-md-6 grid-item cat2 cat3">
                <div className="z-gallery mb-30">
                  <div className="z-gallery__thumb mb-20">
                    <Link to="/course-details">
                      <img
                        className="img-fluid"
                        src="assets/img/course/04.png"
                        alt=""
                      />
                    </Link>
                    <div className="feedback-tag">4.8(256)</div>
                    <div className="heart-icon">
                      <i className="fas fa-heart"></i>
                    </div>
                  </div>
                  <div className="z-gallery__content">
                    <div className="course__tag mb-15">
                      <span>Science</span>
                      <span>Lab</span>
                      <a className="f-right" href="instructor-details">
                        <img
                          className="rounded-circle"
                          src="assets/img/course/in1.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <h4 className="sub-title mb-20">
                      <Link to="/course-details">
                        Take Your Career to the Next Level Future
                      </Link>
                    </h4>
                    <div className="course__meta">
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/time.svg"
                          alt="course-meta"
                        />{" "}
                        12 Class
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/bar-chart.svg"
                          alt="course-meta"
                        />{" "}
                        Higher
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/user.svg"
                          alt="course-meta"
                        />{" "}
                        6395+
                      </span>
                      <span>$260</span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={"/course-details"} className="col-lg-4 col-md-6 grid-item cat5 cat4">
                <div className="z-gallery mb-30">
                  <div className="z-gallery__thumb mb-20">
                    <Link to="/course-details">
                      <img
                        className="img-fluid"
                        src="assets/img/course/05.png"
                        alt=""
                      />
                    </Link>
                    <div className="feedback-tag">4.8(256)</div>
                    <div className="heart-icon">
                      <i className="fas fa-heart"></i>
                    </div>
                  </div>
                  <div className="z-gallery__content">
                    <div className="course__tag mb-15">
                      <span>Science</span>
                      <span>Lab</span>
                      <a className="f-right" href="instructor-details">
                        <img
                          className="rounded-circle"
                          src="assets/img/course/in3.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <h4 className="sub-title mb-20">
                      <Link to="/course-details">
                        Your Career to build for the pro level
                      </Link>
                    </h4>
                    <div className="course__meta">
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/time.svg"
                          alt="course-meta"
                        />{" "}
                        12 Class
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/bar-chart.svg"
                          alt="course-meta"
                        />{" "}
                        Higher
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/user.svg"
                          alt="course-meta"
                        />{" "}
                        6395+
                      </span>
                      <span>$260</span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={"/course-details"} className="col-lg-4 col-md-6 grid-item cat3 cat1">
                <div className="z-gallery mb-30">
                  <div className="z-gallery__thumb mb-20">
                    <Link to="/course-details">
                      <img
                        className="img-fluid"
                        src="assets/img/course/06.png"
                        alt=""
                      />
                    </Link>
                    <div className="feedback-tag">4.8(256)</div>
                    <div className="heart-icon">
                      <i className="fas fa-heart"></i>
                    </div>
                  </div>
                  <div className="z-gallery__content">
                    <div className="course__tag mb-15">
                      <span>Science</span>
                      <span>Lab</span>
                      <a className="f-right" href="instructor-details">
                        <img
                          className="rounded-circle"
                          src="assets/img/course/in3.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <h4 className="sub-title mb-20">
                      <Link to="/course-details">
                        Take A Course For You Biright Future
                      </Link>
                    </h4>
                    <div className="course__meta">
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/time.svg"
                          alt="course-meta"
                        />{" "}
                        12 Class
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/bar-chart.svg"
                          alt="course-meta"
                        />{" "}
                        Higher
                      </span>
                      <span>
                        <img
                          className="icon"
                          src="assets/img/icon/user.svg"
                          alt="course-meta"
                        />{" "}
                        6395+
                      </span>
                      <span>$260</span>
                    </div>
                  </div>
                </div>
              </Link> */}
              </div>
            )}
            <div className="row">
              <div className="col-xxl-12 mt-20 text-center mb-20">
                <a href="courses.html" className="theme_btn">
                  Explore More
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- feature-course end --> */}
        {/* <!--what-loking-for start--> */}
        <section className="what-looking-for pos-rel gradient-bg pt-145 pb-130 pt-md-95 pb-md-80 pt-xs-95 pb-xs-80">
          <div className="what-blur-shape-one"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center mb-55">
                  <h5 className="bottom-line mb-25">Teachers & Students</h5>
                  <h2>What you Looking For?</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="what-box text-center mb-3">
                  <div className="what-box__icon mb-30">
                    <img src="assets/img/icon/phone-operator.svg" alt="" />
                  </div>
                  <h3>Do you want to teach here?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    di nonumy eirmod tempor invidunt ut labore et dolore magn
                    aliq erat.
                  </p>
                  <a href="contact.html" className="theme_btn border_btn">
                    Register Now
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="what-box text-center mb-3">
                  <div className="what-box__icon mb-30">
                    <img src="assets/img/icon/graduate.svg" alt="" />
                  </div>
                  <h3>Do you want to learn here?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    di nonumy eirmod tempor invidunt ut labore et dolore magn
                    aliq erat.
                  </p>
                  <a
                    href="contact.html"
                    className="theme_btn border_btn active"
                  >
                    Register Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--what-loking-for end--> */}
        {/* <!-- subscribe-area start --> */}
        <SubscribeForm />
        {/* <!-- subscribe-area end --> */}
      </main>
    </Layout>
  );
};

export default SearchResults;
