

const ExpertsComp = ({Image, Name, BrandImage}) => {
    return(
        <div className="experts-div" >
            <div className="experts-image">
                <img src={Image} />
            </div>
            <div className="experties">
                <h2>{Name}</h2>
                <img src={BrandImage} />
            </div>
        </div>
    )
}
export default ExpertsComp;