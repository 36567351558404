import "./App.css";
import "./Styles/Style.css";
import Router from "./Routes";
import 'antd/dist/antd.css'
import loadjs from 'loadjs';
import { useEffect } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";


function App() {

  useEffect(() => {
    loadjs([
      "/assets/js/vendor/modernizr-3.5.0.min.js",
      "/assets/js/vendor/jquery-2.2.4.min.js",
      "/assets/js/popper.min.js",
      "/assets/js/bootstrap.min.js",
      "/assets/js/owl.carousel.min.js",
      "/assets/js/isotope.pkgd.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/metisMenu.min.js",
      "/assets/js/jquery.nice-select.js",
      "/assets/js/ajax-form.js",
      "/assets/js/wow.min.js",
      "/assets/js/jquery.counterup.min.js",
      "/assets/js/waypoints.min.js",
      "/assets/js/jquery.scrollUp.min.js",
      "/assets/js/imagesloaded.pkgd.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/jquery.easypiechart.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ],() => {console.log('loadjs loaded')});
  }, []);
  return (
    <>
      
      {/* header */}
     <Router />
    </>
  );
}

export default App;
