import axios from "axios";
import { url } from ".";

export const GetCourses = async () => {
  const { data } = await axios.get(`${url}/courses/allcourses`);
  return data;
};

export const GetSearchedCourses = async (name) => {
  const { data } = await axios.post(`${url}/courses/search`, { name });
  return data;
};

export const GetCourse = async (id) => {
  const { data } = await axios.post(`${url}/courses/getcourse`, { id });
  return data;
};
export const GetCourseVideos = async (id) => {
  const { data } = await axios.post(`${url}/material/getMaterial`, { id });
  return data;
};
export const ReserveCourse = async (course) => {
  // console.log("course reserve ",course)
  const { data } = await axios.post(`${url}/reservation/reserve`, { course });
  return data;
};
export const CheckReserveCourse = async (course) => {
  // console.log("course reserve ",course)
  const { data } = await axios.post(`${url}/reservation/checkIfReserve`, {
    id: course,
  });
  return data;
};
export const getVidNames = async (course) => {
  const { data } = await axios.post(`${url}/material/getMaterial`, {
    id: course,
  });
  return data;
};

export const getSchedules = async (course) => {
  const { data } = await axios.post(`${url}/schedule/getMeetings`, {
    id: course,
  });
  return data;
};

export const getQuiz = async (course) => {
  const { data } = await axios.post(`${url}/quiz/getAllQuizzes`, {
    course,
  });
  return data;
};

export const getAllCourseQuiz = async () => {
  const { data } = await axios.get(`${url}/quiz/getAllCoursesQuizzes`);
  return data;
};

export const getSingleQuiz = async (quiz) => {
  const { data } = await axios.post(`${url}/quiz/getSingleQuiz`, {
    id: quiz,
  });
  return data;
};

export const addToWishlist = async (course) => {
  const { data } = await axios.post(`${url}/wishlist/list`, {
    course,
  });
  return data;
};

export const getWishlist = async () => {
  const { data } = await axios.get(`${url}/wishlist/getlist`);
  return data;
};

export const checkWishlist = async (id) => {
  const { data } = await axios.post(`${url}/wishlist/checkwishlist`, {
    id,
  });
  return data;
};

export const GetEnrolledCourses = async () => {
  const { data } = await axios.get(`${url}/reservation/getUserReservations`);
  return data;
};

export const getVideo = async (id) => {
  const { data } = await axios.post(`${url}/material/getVid`, {
    id,
  });
  return data;
};

export const getAllComments = async (id) => {
  const { data } = await axios.post(`${url}/comments/getall`, {
    id,
  });
  // console.log("commetns ",data)
  return data;
};

export const postComment = async (message, material) => {
  const { data } = await axios.post(`${url}/comments/add`, {
    message,
    material,
  });
  return data;
  // console.log("comment ",payload)
};

export const postReply = async (payload) => {
  const { data } = await axios.post(`${url}/comments/addreply`, payload);
  return data;
};

export const addQuizAnswer = async (payload) => {
  const { data } = await axios.post(`${url}/quiz/addQuizAnswer`, payload);
  return data;
};

export const submitQuiz = async (payload) => {
  const { data } = await axios.post(`${url}/quiz/submitQuiz`, payload);
  return data;
};

export const checkQuizStatus = async (quiz) => {
  // console.log("id ", quiz);
  const { data } = await axios.post(`${url}/quiz/checkQuizStatus`, { quiz });
  // console.log("data ", data);
  return data;
};

export const getPointer = async (quiz) => {
  // console.log("id ", quiz);
  const { data } = await axios.post(`${url}/quiz/getPointer`, { quiz });
  // console.log("data ", data);
  return data;
};

export const courseVisitsByUserId = async (course) => {
  // console.log("course ", course);
  const { data } = await axios.post(`${url}/courses/courseVisits`, {course});
  return data;
};

export const courseVisitsByIP = async (course) => {
  // console.log("course ", course);
  const { data } = await axios.post(`${url}/courses/courseVisitsIP`, {course}); 
  return data;
};

export const getCategories = async () => {
  const { data } = await axios.get(`${url}/category/get-active`);
  return data;
}

export const getCourseByCategory = async (category) => {
  const { data } = await axios.get(`${url}/courses/getCourseByCategory/${category}`);
  return data;
}