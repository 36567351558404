import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <div style={{ minHeight: "100vh", position: "relative" }} className='pt-180 pt-xs-150 pt-150 pb-xs-35'>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
