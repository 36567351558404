import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer class="footer-area pt-70 pb-40">
      <div class="container">
        <div class="row mb-15">
          <div
            class="col-xl-3 col-lg-4 col-md-6  wow fadeInUp2 animated"
            data-wow-delay=".1s"
          >
            <div class="footer__widget mb-30">
              <div class="footer-log mb-20">
                <Link to="/" className="logo">
                  <img src="assets/img/logo/header_logo_one.png" alt="" />
                </Link>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadip scing elitr, sed di
                nonumy eirmod temporinvi dunt ut labore lorem ipsum.
              </p>
              <div class="social-media footer__social mt-30">
                <a href="#">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i class="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a href="#">
                  <i class="fab fa-google-plus-g"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp2 animated"
            data-wow-delay=".3s"
          >
            <div class="footer__widget mb-30 pl-40 pl-md-0 pl-xs-0">
              <h6 class="widget-title mb-35">Contact us</h6>
              <ul class="fot-list">
                <li>
                  <a href="#">info@example.com</a>
                </li>
                <li>
                  <a href="#">+00 235 695 58</a>
                </li>
                <li>
                  <a href="#">Terms & Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <a href="#">Our Careers</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6  wow fadeInUp2 animated"
            data-wow-delay=".5s"
          >
            <div class="footer__widget mb-25 pl-90 pl-md-0 pl-xs-0">
              <h6 class="widget-title mb-35">Quick Links</h6>
              <ul class="fot-list">
                <li>
                  <Link to="/about">About US</Link>
                </li>
                <li>
                  <a href="#">Explore Pages</a>
                </li>
                <li>
                  <a href="#">Our Services</a>
                </li>
                <li>
                  <a href="#">Destinations</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-6 col-md-6  wow fadeInUp2 animated"
            data-wow-delay=".7s"
          >
            <div class="footer__widget mb-30 pl-150 pl-lg-0 pl-md-0 pl-xs-0">
              <h6 class="widget-title mb-35">Features</h6>
              <ul class="fot-list mb-30">
                <li>
                  <Link to="/">Home Page</Link>{" "}
                </li>
                <li>
                  <a href="#">Testimonials</a>
                </li>
                <li>
                  <a href="#latestNews">Latest News</a>
                </li>
                <li>
                  <a href="#">Help Center</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="copy-right-area border-bot pt-40">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="copyright text-center">
                <h5>
                  Copyright@ 2021 <a href="#">EDME</a>. All Rights Reserved
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
