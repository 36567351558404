import axios from "axios";
import { url } from ".";

export const GetQuestions = async () => {
  const { data } = await axios.get(`${url}/questions/getAllQuestions`);
  return data;
};
export const PostAnswers = async (answers) => {
  const { data } = await axios.post(`${url}/mentorrequest/create`,{answers});
  return data;
};