import React, { useState, useEffect } from "react";

// import { useDispatch,useSelector } from "react-redux";
import { getAllComments } from "../services/courses";
// import { getVideo, getAllComments } from "../redux/index";
import { useParams } from "react-router-dom";
// import CommentsCard from "../components/Cards/CommentsCard";
import AddComments from "../components/AddComments";
import CommentsCard from "./CommentsCard";

const Comments = () => {
  const param = useParams();
  const [video, setVideo] = useState(null);
  const [comments, setComments] = useState([]);
  //   const reduxComments = useSelector(state => state.course.comments)

  const [type, setType] = useState();

  const fetchComments = async () => {
    let data = await getAllComments(param.id);
    setComments(data);
    console.log("comments ", data);
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <>
      <div className="comments-header">
        <p>3 Comments</p>
        {/* <button className="gf_btn">
          <Icon name="align left" />
          Sort by
        </button> */}
      </div>
      <AddComments type="comment" />
      {comments.length &&
        comments?.map((comment) => (
          <CommentsCard key={comment?._id} comment={comment} />
        ))}
    </>
  );
};

export default Comments;
