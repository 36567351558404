import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../../layout/Layout";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ReserveCourse } from "../../services/courses";

const CheckoutForm = () => {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleInputFocus = (event) => {
    console.log(event.target.name);
    const name = event.target.name;
    setInputs((values) => ({ ...values, focus: name }));
  };
  const handleSubmit = async (e) => {
    // navigate("/auth");
    if (e) e.preventDefault();
    try {
      // location?.state?.id
      // console.log("courseid ",localStorage.getItem("courseid"))
      let expRegex = new RegExp(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/gm);
      if (
        !inputs.name ||
        !inputs.email ||
        !inputs.number ||
        !inputs.age ||
        !inputs.cvc ||
        !inputs.expiry ||
        !inputs.cardName ||
        !inputs.cardNumber
      ) {
        toast.error("Please fill all the fields");
        return;
      }
      if (inputs.cardNumber.length < 16) {
        toast.error("Please enter valid card number");
        return;
      }
      if (inputs.cvc.length < 3) {
        toast.error("Please enter valid cvc number");
        return;
      }
      if (!expRegex.test(inputs.expiry)) {
        toast.error("Please enter valid expiry date");
        return;
      }
      const result = await ReserveCourse(localStorage.getItem("courseid"));
      // console.log("result ",result);
      if (result) {
        toast.success("Course Reserved Successfully");
        navigate("/");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Server Error");
    }
  };
  useEffect(() => {
    console.log(location.state);
    if (!location?.state) navigate("/");
  }, []);
  return (
    <Layout>
      <ToastContainer />
      <section className="contact-form-area ">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10">
              <div className="contact-form-wrapper text-center mb-30">
                <h2 className="mb-45">Checkout</h2>
                <form
                  className="row gx-3 comments-form contact-form gradient-bg pt-30 pl-20 pr-20"
                  onSubmit={handleSubmit}
                >
                  <h3 className="text-start mb-20">Personal Information</h3>
                  <div className="col-lg-6 mb-30">
                    <h6 className="text-start mb-10">Full Name</h6>
                    <input
                      type="text"
                      name="name"
                      placeholder="Username"
                      value={inputs.name || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-6 mb-30">
                    <h6 className="text-start mb-10">Email</h6>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={inputs.email || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-6 mb-30">
                    <h6 className="text-start mb-10">Phone Number</h6>
                    <input
                      type="number"
                      name="number"
                      placeholder="Phone Number"
                      value={inputs.number || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-6 mb-30">
                    <h6 className="text-start mb-10">Age</h6>
                    <input
                      type="number"
                      name="age"
                      placeholder="Age"
                      value={inputs.age || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <h3 className="text-start mb-20">Payment Details</h3>
                  <div className="col-lg-6 mb-30">
                    <div className="col-lg-12 mb-30">
                      <h6 className="text-start mb-10">Card Number</h6>
                      <input
                        type="number"
                        name="cardNumber"
                        placeholder="Card Number"
                        value={inputs.cardNumber || ""}
                        onChange={handleChange}
                        onFocus={handleInputFocus}
                      />
                    </div>
                    <div className="col-lg-12 mb-30">
                      <h6 className="text-start mb-10">Card Holder Name</h6>
                      <input
                        type="text"
                        name="cardName"
                        placeholder="Name on Card"
                        value={inputs.cardName || ""}
                        onChange={handleChange}
                        onFocus={handleInputFocus}
                      />
                    </div>
                    <div className="col-lg-12 d-flex flex-wrap">
                      <div className="col-lg-8 col-sm-12 mb-30 pe-xl-1">
                        <h6 className="text-start mb-10">Expiry Date</h6>
                        <input
                          type="number"
                          name="expiry"
                          placeholder="Valid Thru"
                          value={inputs.expiry || ""}
                          onChange={handleChange}
                          onFocus={handleInputFocus}
                        />
                      </div>
                      <div className="col-lg-4 col-sm-12 mb-30">
                        <h6 className="text-start mb-10">CVC</h6>
                        <input
                          type="number"
                          name="cvc"
                          placeholder="CVC"
                          value={inputs.cvc || ""}
                          onChange={handleChange}
                          onFocus={handleInputFocus}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-30">
                    <Cards
                      cvc={inputs.cvc || ""}
                      expiry={inputs.expiry || ""}
                      focused={inputs.focus}
                      name={inputs.cardName || ""}
                      number={inputs.cardNumber || ""}
                    />
                  </div>

                  <button className="theme_btn message_btn mt-20">
                    {loading ? (
                      <div class="spinner-border text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Checkout"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CheckoutForm;
