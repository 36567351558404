import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { getSchedules } from "../services/courses";

const MaterialSchedule = ({schedules}) => {
  // const [schedules, setSchedules] = useState(null);

  const navigate = useNavigate();
  // const { id } = useParams();

  // const fetchCourse = async () => {
  //   const vid = await getSchedules(id);
  //   setSchedules(vid);
  // };
  // // console.log("vidNames ", vidNames);
  // useEffect(() => {
  //   fetchCourse();
  // }, []);

  return (
    <main>
      {/* <!--page-title-area start--> */}
      <section className="schedule-card-area pt-150 pb-120 pt-md-100 pb-md-70 pt-xs-100 pb-xs-70">
        <div className="container">
          {!schedules?.length && (
            <div className="d-flex justify-content-center align-items-center mb-40">
              <div className="spinner-border " role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {schedules?.length &&
            schedules?.map((schedule, index) => (
              <div className="meetingsection row">
                <h2 className="courses-title mb-30">{schedule?.title}</h2>
                <h5>{schedule?.startTime}</h5>
                <h5>Meeting ID: {schedule?.meetingid}</h5>
                <div className="meetinactions">
                  <button className="gf_btn">Join</button>
                </div>
              </div>
            ))}
        </div>

      </section>
    </main>
  );
};

export default MaterialSchedule;
