import React, { useState, useEffect } from "react";

import img1 from "../assets/Capture.jpeg";

// import AddComments from "../AddComments";

const ReplyCard = (props) => {
  const { comment } = props;
  const [commentID, setCommentID] = useState("");

  return (
    <>
      <div className="row">
        {/* <Col xs={24} sm={12} lg={2}> */}
        <div className="col-xl-1 col-lg-1">
          <img src={img1}></img>
        </div>
        {/* </Col> */}
        {/* <Col xs={24} sm={12} lg={21}> */}
        <div className="add-comment-area col-xl-10 col-lg-10">
          <div className="u-detail">
            <p className="u-name">
              {comment?.mentor ? comment?.mentor?.name : comment?.user?.name}
            </p>
            {/* {console.log("sadsafasfasf ", comment)}  */}
            <p className="u-date">1 day ago</p>
          </div>
          <div className="comment-text">
            <p>{comment?.message}</p>
          </div>
        </div>
        {/* </Col> */}
      </div>
      {/* <div className="reply">
        <AddComments type="reply" />
      </div> */}
    </>
  );
};

export default ReplyCard;
