import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { getVideo } from "../services/courses";
import { useLocation } from "react-router-dom";
import Comments from "../components/Comments";

function Player() {
  const location = useLocation();
  const [video, setVideos] = useState(null);
  const [index, setIndex] = useState(location.state.index);
  const fetchCourses = async (index) => {
    let data = await getVideo(location.state.vidNames[index]._id);
    setVideos(data[0]);
    // console.log(data[0]);
  };

  const next = () => {
    if (index != location.state.vidNames.length - 1) {
      setIndex(index + 1);
    }
  };
  const previous = () => {
    if (index != 0) {
      setIndex(index - 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCourses(index);
  }, [index]);

  return (
    <Layout>
      <section className="schedule-card-area pt-150 pb-120 pt-md-100 pb-md-70 pt-xs-100 pb-xs-70">
        <div className="container">
          <div className="vid-player">
            <div className="row">
              <div>
                <div className="player-head">
                  <video
                    src={video?.thumbnail}
                    controls
                    style={{
                      // width: "50vw",
                      height: "50vh",
                      minHeight: "600px",
                    }}
                  ></video>
                  <div className="title-video">
                    <h3>{video?.name}</h3>
                    <div>
                      <button
                        onClick={next}
                        disabled={index == location.state.vidNames.length - 1}
                      >
                        Next
                      </button>
                      <button
                        style={{
                          marginRight: "10px",
                        }}
                        onClick={previous}
                        disabled={index == 0}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                  {/* <div className="rating-box">
                <div className="rating-head">
                  <p>Rating</p>
                  <Typography.Title level={4}> ({rating})</Typography.Title>
                </div>
                <Rate onChange={setValue} value={value} />
              </div> */}
                </div>
                <Comments />
              </div>
              {/* <Col xs={24} sm={12} lg={7}>
            <div className="player-rec">
              {video?.res2.length &&
                video?.res2?.map((material) => (
                  <PlayerCard key={material._id} material={material} />
                ))}
            </div>
          </Col> */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Player;
