import React, { useState, useEffect } from "react";

// import { useDispatch } from "react-redux";
import img1 from "../assets/Capture.jpeg";

import { useParams } from "react-router-dom";
import { postComment, postReply } from "../services/courses";

const AddComments = (props) => {
  //   const dispatch = useDispatch();
  const param = useParams();
  const { type, id } = props;

  const [commentText, setcommentText] = useState("");

  const saveState = (event) => {
    setcommentText(event.target.value);
  };

  const saveComment = async () => {
    // alert(commetnText)
    const formData = new FormData();
    if (type === "comment") {
      setcommentText("");
      let res = await postComment(commentText,param.id);
    } else {
      setcommentText("");
      let payload = {
        id,
        message: commentText,
      };
      let res = await postReply(payload);
    }
  };
  return (
    <>
      <div className="add-comment">
        <div className="row">
          {/* <Col xs={24} sm={12} lg={2}> */}
          <div className="col-xl-1 col-lg-1">
            <img src={img1}></img>
          </div>
          {/* </Col> */}
          {/* <Col xs={24} sm={12} lg={21}> */}
          <div className="add-comment-area col-xl-11 col-lg-11">
            <input
              onChange={saveState}
              value={commentText}
              placeholder="Add Comment"
              type="text"
            />
            <button onClick={saveComment}>Comment</button>
          </div>
          {/* </Col> */}
        </div>
      </div>
    </>
  );
};

export default AddComments;
