import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import {
  getSingleQuiz,
  getPointer,
  addQuizAnswer,
  submitQuiz,
} from "../services/courses";
import { useParams, useNavigate } from "react-router-dom";
// import {
//   NotificationContainer,
//   NotificationManager,
// } from "react-notifications";

function Quiz() {
  const [quizzes, setQuiz] = useState(null);
  const [pointer, setPointer] = useState(0);
  const [points, setPoints] = useState(0);
  const [value, setValue] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [isQuesVisible, setIsQuesVisible] = useState(true);
  //   const [chck, setChck] = useState(false);
  let val = 1;
  const navigate = useNavigate();

  const param = useParams();

  const onNext = () => {
    if (value !== 0) {
      var quiz = quizzes?._id;
      var questionid = quizzes?.questions[pointer]?.question;
      var ans = quizzes?.questions[pointer]?.answer;
      var isCorrect = false;
      console.log("value ", value);
      console.log("ans ", ans);
      if (value == ans) {
        isCorrect = true;
      }
      setPointer(pointer + 1);
      setValue(0);
      addQuizAnswer({ quiz, answer: { questionid, isCorrect } });
    }
  };

  const onSubmit = () => {
    if (value !== 0) {
      var quiz = quizzes?._id;
      var questionid = quizzes?.questions[pointer]?.question;
      var ans = quizzes?.questions[pointer]?.answer;
      var isCorrect = false;
      console.log("value ", value);
      console.log("ans ", ans);
      if (value == ans) {
        isCorrect = true;
      }
      // setPointer(pointer + 1);
      setValue(0);
      submitQuiz({ quiz, answer: { questionid, isCorrect } });
      navigate("/learning")
    }

    // if (value !== 0) {
    //   setAnswers((answers) => [...answers, value]);
    //   setPointer(1);
    //   if (value === quizzes.questions[pointer].answer) {
    //     setPoints(points + 1);
    //   }
    //   setValue(0);
    //   setIsQuesVisible(false);
    // }
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };
  //   console.log("ans ", value);
  const fetchCourses = async () => {
    let data = await getSingleQuiz(param.id);
    let pnt = await getPointer(param.id);
    setQuiz(data[0]);

    if (pnt) {
      setPointer(pnt);
    }
    // console.log("pont ", pnt);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCourses();
  }, []);

  return (
    <Layout>
      <main>
        <section className="schedule-card-area pt-150 pb-120 pt-md-100 pb-md-70 pt-xs-100 pb-xs-70">
          <div className="container">
            {/* <div className="quiz-containor"> */}
            <h1>Quiz</h1>
            <div className="quiz-ques-container">
              <h3>
                Question {pointer + 1} of {quizzes?.questions?.length}
              </h3>
              <div className="quiz-question">
                <p>{quizzes?.questions[pointer].question}</p>
              </div>
              <div className="quiz-answer">
                {/* <Radio.Group onChange={onChange} value={value}> */}
                {/* <Space direction="vertical"> */}
                {quizzes?.questions[pointer]?.options?.length &&
                  quizzes?.questions[pointer]?.options?.map((option, index) => (
                    <>
                      <div class="form-check radiotxt">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id={"flexRadioDefault" + (index + 1)}
                          value={index + 1}
                          onChange={onChange}
                          //   checked={false}
                        ></input>
                        <label
                          class="form-check-label"
                          for={"flexRadioDefault" + (index + 1)}
                        >
                          <p className="radiotext">{option}</p>
                        </label>
                      </div>

                      {/* <div className="radiotxt">
                        <p className="radiotext">{option}</p>
                      </div> */}
                    </>
                  ))}
              </div>
              <div>
                {pointer < quizzes?.questions?.length - 1 ? (
                  <button onClick={onNext} className="next-btn gf_btn">
                    Next
                  </button>
                ) : (
                  //   <button onClick={onNext}>Next </button>
                  <button onClick={onSubmit} className="next-btn gf_btn">
                    Submit
                  </button>
                  // <button onClick={onSubmit}>Submit </button>
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default Quiz;
