import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { checkQuizStatus } from "../services/courses";

const MaterialQuiz = ({ quizzes }) => {
  const [status, setStatus] = useState(null);

  const checkStatus = async () => {
    let data = await checkQuizStatus(quizzes._id);
    setStatus(data);
    console.log("status ",data)
  };

  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <main>
      <div className="meetingsection row">
        <h2 className="courses-title mb-30">{quizzes?.title}</h2>
        <h5>
          Passing <b>60%</b>
        </h5>
        <div className="meetinactions">
          {status == "started" ? (
            <Link to={"/quiz/" + quizzes._id}>
              <button className="gf_btn">Resume</button>
            </Link>
          ) : status == "Not started" ? (
            <Link to={"/quiz/" + quizzes._id}>
              <button className="gf_btn">Start</button>
            </Link>
          ) : (
            <button className="gf_btn">Score : {status}</button>
          )}
        </div>
      </div>
      {/* <!--page-title-area start--> */}
      {/* <section className="schedule-card-area pt-150 pb-120 pt-md-100 pb-md-70 pt-xs-100 pb-xs-70">
        <div className="container">
          {!quizzes?.length && (
            <div className="d-flex justify-content-center align-items-center mb-40">
              <div className="spinner-border " role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {quizzes?.length &&
            quizzes?.map((quiz, index) => (
              <div className="meetingsection row">
                <h2 className="courses-title mb-30">{quiz?.title}</h2>
                <h5>
                  Passing <b>60%</b>
                </h5>
                <div className="meetinactions">
                  <Link to={"/quiz/" + quiz._id}>
                    <button className="gf_btn">Start</button>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </section> */}
    </main>
  );
};

export default MaterialQuiz;
