import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubscribeForm from "../components/SubscribeForm";
import Layout from "../layout/Layout";
import { getSchedules, getQuiz, getVidNames } from "../services/courses";
import MaterialVideos from "../components/material-videos";
import MaterialSchedule from "../components/material-schedule";
import MaterialQuiz from "../components/material-quiz";
const Material = () => {
  // const [videosShow, setVideosShow] = useState(true);
  // const [scheduleShow, setScheduleShow] = useState(false);
  // const [quizShow, setQuizShow] = useState(false);
  const [vidNames, setVidNames] = useState(null);
  const [schedules, setSchedules] = useState(null);
  const [quizzes, setQuizzes] = useState(null);
  const [active, setActive] = useState("videos");
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchCourse = async () => {
    const vid = await getVidNames(id);
    const sch = await getSchedules(id);
    const quiz = await getQuiz(id);
    setVidNames(vid);
    setSchedules(sch);
    setQuizzes(quiz);
  };
  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/")
    }
    fetchCourse();
  }, []);

  // const changeComponentToSchedule = async () => {
    // setVideosShow(false);
    // setScheduleShow(true);
    // setQuizShow(false);
  // };

  // const changeComponentToVideos = async () => {
    // setVideosShow(true);
    // setScheduleShow(false);
    // setQuizShow(false);
  // };

  // const changeComponentToQuiz = async () => {
    // setVideosShow(false);
    // setScheduleShow(false);
    // setQuizShow(true);
  // };

  return (
    // <BrowserRouter>
    <Layout>
      <main>
        {/* <!--page-title-area start--> */}
        <section
          className="page-title-area d-flex align-items-end "
          style={{
            backgroundImage: "url('assets/img/page-title-bg/01.jpg')",
          }}
        >
          {/* <div className="container">
            
          </div> */}
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="page-title-wrapper mb-50">
                  <h1 className="page-title mb-25">Course</h1>
                  <div className="breadcrumb-list">
                    <ul className="breadcrumb">
                      <li>
                        <a href="index.html">Home - Pages -</a>
                      </li>
                      <li>
                        <a href="#">Courses Details</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--page-title-area end--> */}
        {/* <!--course-details-area start--> */}
        <section className="feature-course feature-tabs pt-150 pb-130 pt-md-95 pb-md-80 pt-xs-95 pb-xs-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 text-center">
                <div className="portfolio-menu mb-60">
                  <button
                    onClick={()=>{setActive("videos")}}
                    className={`${active === 'videos' && 'active'} gf_btn`}
                    data-filter="*"
                  >
                    Videos
                  </button>
                  {/* <button
                    onClick={()=>{setActive("schedule")}}
                    className={`${active === 'schedule' && 'active'} gf_btn`}
                    data-filter=".cat1"
                  >
                    Schedules
                  </button>
                  <button
                    onClick={()=>{setActive("quiz")}}
                    className={`${active === 'quiz' && 'active'} gf_btn`}
                    data-filter=".cat2"
                  >
                    Quizzes
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {active==="schedule" ? (
          schedules?.length ? (
            <MaterialSchedule schedules={schedules} />
          ) : (
            <div className="d-flex justify-content-center align-items-center mb-40">
              <div className="spinner-border " role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )
        ) : active==="videos" ? (
          vidNames?.length ? (
            <MaterialVideos vidNames={vidNames} />
          ) : (
            <div className="d-flex justify-content-center align-items-center mb-40">
              <div className="spinner-border " role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )
        ) : active==="quiz" ? (
          quizzes?.length ? (
            <MaterialQuiz quizzes={quizzes} />
          ) : (
            <div className="d-flex justify-content-center align-items-center mb-40">
              <div className="spinner-border " role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )
        ) : null}

        {/* <!-- subscribe-area start --> */}
        <SubscribeForm />
        {/* <!-- subscribe-area end --> */}
      </main>
    </Layout>
  );
};

export default Material;
