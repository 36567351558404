import { Row, Col } from "antd";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React from "react";
import ExpertsComp from "./ExpertsComp";
import Instructor1 from "../assets/images/Explore-Experienced-Instructor_01.png";
import Instructor2 from "../assets/images/Explore-Experienced-Instructor_02.png";
import Instructor3 from "../assets/images/Explore-Experienced-Instructor_03.png";
import Instructor4 from "../assets/images/Explore-Experienced-Instructor_04.png";
import Instructor5 from "../assets/images/Explore-Experienced-Instructor_05.png";
import Instructor6 from "../assets/images/Explore-Experienced-Instructor_06.png";
import Instructor7 from "../assets/images/Explore-Experienced-Instructor_07.png";
import Instructor8 from "../assets/images/Explore-Experienced-Instructor_08.png";
import Instructor9 from "../assets/images/Explore-Experienced-Instructor_09.png";
import Mediacom from "../assets/mediacom.png";
import Digitz from "../assets/digitz-logo.png";
import Mindshare from "../assets/mindshare-logo.png";
import Publicis from "../assets/publicis-logo-removebg-preview.png";
import Starcom from "../assets/starcom-logo.png";
import UM from "../assets/UM_Logo-removebg-preview.png";
import OMd from "../assets/OMD_Logo-removebg-preview.png";
import Google from "../assets/Google.png";
import Meta from "../assets/meta.png";
import Assembly from "../assets/assembly.jpeg";
import Spark from "../assets/spark.jpeg";
import Mediavest from "../assets/mediavest.jpeg";
import EasyPaisa from "../assets/easypaisa.png";
import Loreal from "../assets/Loreal.png";
import Tcf from "../assets/tcf.png";

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3.5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ExpertsCarousel = () => {
  return (
    <Carousel responsive={responsive} infinite>
      <ExpertsComp
        Image={Instructor1}
        Name="Ali Jaffer"
        BrandImage={Mediacom}
      />
      <ExpertsComp
        Image={Instructor2}
        Name="Salman Yahya"
        BrandImage={Digitz}
      />
      <ExpertsComp
        Image={Instructor3}
        Name="Ghazal Farouqi"
        BrandImage={Starcom}
      />
      <ExpertsComp Image={Instructor4} Name="Farah Naz" BrandImage={Starcom} />
      <ExpertsComp Image={Instructor5} Name="Wajahat" BrandImage={Mediavest} />
      <ExpertsComp
        Image={Instructor1}
        Name="Hasan Puri"
        BrandImage={Publicis}
      />
      <ExpertsComp
        Image={Instructor2}
        Name="Aamir Mohsin"
        BrandImage={Mindshare}
      />
      <ExpertsComp
        Image={Instructor3}
        Name="Rimsha Khan"
        BrandImage={Assembly}
      />
      <ExpertsComp Image={Instructor4} Name="Zoya Shah" BrandImage={Spark} />
      <ExpertsComp Image={Instructor5} Name="Abrar" BrandImage={EasyPaisa} />
      <ExpertsComp
        Image={Instructor1}
        Name="Hamza Hussain"
        BrandImage={Loreal}
      />
      <ExpertsComp Image={Instructor2} Name="Hasnain M" BrandImage={Tcf} />
    </Carousel>
  );
};
export default ExpertsCarousel;
