import React from "react";
import { useEffect } from "react";
import Layout from "../../layout/Layout";
import loadjs from "loadjs";
import { useLocation } from "react-router-dom";
import SubscribeForm from "../../components/SubscribeForm";

const InstructorProfile = () => {
  const location = useLocation()
  
   useEffect(() => {
    loadjs([
      "/assets/js/vendor/modernizr-3.5.0.min.js",
      "/assets/js/vendor/jquery-2.2.4.min.js",
      "/assets/js/popper.min.js",
      "/assets/js/bootstrap.min.js",
      "/assets/js/owl.carousel.min.js",
      "/assets/js/isotope.pkgd.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/metisMenu.min.js",
      "/assets/js/jquery.nice-select.js",
      "/assets/js/ajax-form.js",
      "/assets/js/wow.min.js",
      "/assets/js/jquery.counterup.min.js",
      "/assets/js/waypoints.min.js",
      "/assets/js/jquery.scrollUp.min.js",
      "/assets/js/imagesloaded.pkgd.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/jquery.easypiechart.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ],() => {console.log('loadjs loaded')});
  }, [location])
  return (
    <Layout>
      <main>
        {/* <!--page-title-area start--> */}
        <section class="page-title-area d-flex align-items-end">
          <div class="container">
            <div class="row align-items-end">
              <div class="col-lg-12">
                <div class="page-title-wrapper mb-50">
                  <h1 class="page-title mb-25">Instructor-profile</h1>
                  <div class="breadcrumb-list">
                    <ul class="breadcrumb">
                      <li>
                        <a href="index.html">Home - Pages -</a>
                      </li>
                      <li>
                        <a href="#">Instructor Profile</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--page-title-area end--> */}
        {/* <!--instructor-details-area start--> */}
        <section class="instructor-details-area pt-145 pb-110 pt-md-95 pb-md-60 pt-xs-95 pb-xs-60">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div
                  class="instructor-profile-img mb-30"
                  style={{
                    backgroundImage: "url('assets/img/instructor/13.jpg')",
                  }}
                ></div>
              </div>
              <div class="col-xl-6 col-lg-12">
                <div class="instructor-profile">
                  <h2>Instructor Profile</h2>
                  <ul class="profile-list mb-50">
                    <li>
                      Name : <span>Chris Jordan</span>
                    </li>
                    <li>
                      Mobile Num: <span>+(123) 125-856-23</span>
                    </li>
                    <li>
                      Address : <span>24/7 Street Road , NY, USA</span>
                    </li>
                    <li>
                      Email : <span>info@example.com</span>
                    </li>
                    <li>
                      Website : <span>Chrisjordan.com</span>
                    </li>
                    <li>
                      <div class="social-media">
                        <a href="#">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-youtube"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                  <ul class="work-progress">
                    <li>
                      <div class="chart1 mb-30" data-percent="75">
                        <div class="progress-content">
                          <h6>Design</h6>
                          <b>75%</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="chart1 mb-30" data-percent="90">
                        <div class="progress-content">
                          <h6>Arts</h6>
                          <b>90%</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="chart1 mb-30" data-percent="95">
                        <div class="progress-content">
                          <h6>Business</h6>
                          <b>95%</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="chart1 mb-30" data-percent="65">
                        <div class="progress-content">
                          <h6>Career</h6>
                          <b>65%</b>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12">
                <div class="instructor-profile">
                  <h2>Courses Info</h2>
                  <div class="star-icon mb-20">
                    <a href="#">
                      <i class="fas fa-star"></i>
                    </a>
                    <a href="#">
                      <i class="fas fa-star"></i>
                    </a>
                    <a href="#">
                      <i class="fas fa-star"></i>
                    </a>
                    <a href="#">
                      <i class="fas fa-star"></i>
                    </a>
                    <a href="#">
                      <i class="fas fa-star"></i>
                    </a>
                  </div>
                  <p class="mb-25">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum.
                  </p>
                  <h5 class="total-stu pt-30">
                    <span>
                      <img
                        src="assets/img/icon/avatar-outline-badged-2.svg"
                        alt=""
                      />{" "}
                      36549+ Students
                    </span>{" "}
                    <span class="float-sm-end">
                      <img src="assets/img/icon/course.svg" alt="" /> 36549+
                      Students
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--instructor-details-area end--> */}
        {/* <!--what-loking-for start--> */}
        <section class="what-looking-for pos-rel gradient-bg pt-145 pb-130 pt-md-95 pb-md-80 pt-xs-95 pb-xs-80">
          <div class="what-blur-shape-one"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="section-title text-center mb-55">
                  <h5 class="bottom-line mb-25">Teachers & Students</h5>
                  <h2>What you Looking For?</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6">
                <div class="what-box text-center mb-3">
                  <div class="what-box__icon mb-30">
                    <img src="assets/img/icon/phone-operator.svg" alt="" />
                  </div>
                  <h3>Do you want to teach here?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    di nonumy eirmod tempor invidunt ut labore et dolore magn
                    aliq erat.
                  </p>
                  <a href="contact.html" class="theme_btn border_btn">
                    Register Now
                  </a>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6">
                <div class="what-box text-center mb-3">
                  <div class="what-box__icon mb-30">
                    <img src="assets/img/icon/graduate.svg" alt="" />
                  </div>
                  <h3>Do you want to learn here?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    di nonumy eirmod tempor invidunt ut labore et dolore magn
                    aliq erat.
                  </p>
                  <a href="contact.html" class="theme_btn border_btn active">
                    Register Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--what-loking-for end--> */}
        {/* <!-- subscribe-area start --> */}
        <SubscribeForm />
        {/* <!-- subscribe-area end --> */}
      </main>
    </Layout>
  );
};

export default InstructorProfile;
