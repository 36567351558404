import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { Login, login, Register, signup } from "../../services/auth";
import { ToastContainer, toast } from "react-toastify";

const Signup = () => {
  const [inputs, setInputs] = useState({});
  const [isRegister, setIsRegister] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let result;
      if (isRegister) {
        result = await Register(inputs);
        setLoading(false);
        setIsRegister(false);
        toast.success(result?.message);
      } else {
        result = await Login(inputs);
        localStorage.setItem("token", result?.token);
        localStorage.setItem("userId", result?.user?._id);
        localStorage.setItem("user", result?.user?.name);

        setLoading(false);
        console.log(result);
        toast.success(result?.message);
        console.log(location?.state);
        if (location?.state?.key === "enroll") {
          navigate("/checkout", {
            state: location.state.id,
          });
          return;
        }
        navigate("/");
      }
    } catch (error) {
      console.log(error?.response?.data?.message || error);
      toast.error(error?.response?.data?.message || "Server Error");
      setLoading(false);
    }
  };
  const handleFormChange = () => {
    setIsRegister((value) => !value);
    setInputs({});
  };

  // component
  const SubmitButton = ({ text }) => {
    return (
      <button className="theme_btn message_btn mt-20">
        {loading ? (
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          text
        )}
      </button>
    );
  };

  useEffect(() => {
    try {
      window.scrollTo(0,0)
      if (localStorage.getItem("token")) {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <Layout>
      <ToastContainer />
      <section className="contact-form-area ">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6">
              <div className="contact-form-wrapper text-center mb-30">
                <h2 className="mb-45">{isRegister ? "Register" : "Login"}</h2>
                <form
                  className="row gx-3 comments-form contact-form"
                  onSubmit={handleSubmit}
                >
                  {isRegister && (
                    <div className="col-lg-12 mb-30">
                      <input
                        type="text"
                        name="name"
                        placeholder="Username"
                        value={inputs.name || ""}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  <div className="col-lg-12 mb-30">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={inputs.email || ""}
                      onChange={handleChange}
                    />
                  </div>
                  {isRegister && (
                    <div className="col-lg-12 mb-30">
                      <input
                        type="number"
                        name="number"
                        placeholder="Phone Number"
                        value={inputs.number || ""}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {isRegister && (
                    <div className="col-lg-12 mb-30">
                      <input
                        type="number"
                        name="age"
                        placeholder="Age"
                        value={inputs.age || ""}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  <div className="col-lg-12 mb-30">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={inputs.password || ""}
                      onChange={handleChange}
                    />
                  </div>
                  {isRegister && (
                    <div className="col-lg-12 mb-30">
                      <input
                        type="password"
                        name="password2"
                        placeholder="Confirm Password"
                        value={inputs.password2 || ""}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {isRegister ? (
                    <SubmitButton text="Register" />
                  ) : (
                    <SubmitButton text="Login" />
                  )}
                </form>
                {isRegister ? (
                  <p className="mt-30">
                    Already Have An Account?{" "}
                    <span
                      onClick={handleFormChange}
                      className="theme-color fw-bold cursor-pointer"
                    >
                      Login
                    </span>{" "}
                    Here
                  </p>
                ) : (
                  <p className="mt-30">
                    Don't Have An Account?{" "}
                    <span
                      onClick={handleFormChange}
                      className="theme-color fw-bold cursor-pointer"
                    >
                      Register
                    </span>{" "}
                    Here
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Signup;
