import React from "react";
import SubscribeForm from "../../components/SubscribeForm";
import Layout from "../../layout/Layout";
import { useEffect } from "react";
const FAQ = () => {
  
  useEffect(()=>{
    window.scrollTo(0,0)
  })

  return (
    <Layout>
      <main>
        {/* <!--page-title-area start--> */}
        <section
          class="page-title-area d-flex align-items-end"
          style={{ backgroundImage: "url('assets/img/page-title-bg/01.jpg')" }}
        >
          <div class="container">
            <div class="row align-items-end">
              <div class="col-lg-12">
                <div class="page-title-wrapper mb-50">
                  <h1 class="page-title mb-25">FAQ</h1>
                  <div class="breadcrumb-list">
                    <ul class="breadcrumb">
                      <li>
                        <a href="index.html">Home - Pages -</a>
                      </li>
                      <li>
                        <a href="#">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--page-title-area end--> */}
        {/* <!-- faq-area start --> */}
        <section class="faq-area pt-150 pb-120 pt-xs-95 pb-xs-90">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-12">
                <div class="faq-que-list mb-30">
                  <div class="section-title text-center mb-45">
                    <h2 class="mb-25">Frequently Asked Question</h2>
                  </div>
                  <div class="accordion accordion-two" id="accoedion-ex-two">
                    <div class="accordion-item mb-30">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is the best definition of education?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accoedion-ex-two"
                      >
                        <div class="accordion-body">
                          <p>
                            An attack of the ague sent him home, and on
                            recovery, having resolved to attend a high school
                            and fit himself to become a teacher, he passed the
                            next four years in a hard struggle with poverty and
                            in an earnest effort to secure an education,
                            studying for a short time in the Geauga Seminary
                            atChester, Ohio.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item mb-30">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          What is the true meaning of education?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accoedion-ex-two"
                      >
                        <div class="accordion-body">
                          <p>
                            An attack of the ague sent him home, and on
                            recovery, having resolved to attend a high school
                            and fit himself to become a teacher, he passed the
                            next four years in a hard struggle with poverty and
                            in an earnest effort to secure an education,
                            studying for a short time in the Geauga Seminary
                            atChester, Ohio.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item mb-30">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Why is education important?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accoedion-ex-two"
                      >
                        <div class="accordion-body">
                          <p>
                            An attack of the ague sent him home, and on
                            recovery, having resolved to attend a high school
                            and fit himself to become a teacher, he passed the
                            next four years in a hard struggle with poverty and
                            in an earnest effort to secure an education,
                            studying for a short time in the Geauga Seminary
                            atChester, Ohio.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item mb-30">
                      <h2 class="accordion-header" id="headingFour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          What are 3 types of education?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accoedion-ex-two"
                      >
                        <div class="accordion-body">
                          <p>
                            An attack of the ague sent him home, and on
                            recovery, having resolved to attend a high school
                            and fit himself to become a teacher, he passed the
                            next four years in a hard struggle with poverty and
                            in an earnest effort to secure an education,
                            studying for a short time in the Geauga Seminary
                            atChester, Ohio.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item mb-30">
                      <h2 class="accordion-header" id="headingFive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Who is the father of education?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        aria-labelledby="collapseFour"
                        data-bs-parent="#accoedion-ex-two"
                      >
                        <div class="accordion-body">
                          <p>
                            An attack of the ague sent him home, and on
                            recovery, having resolved to attend a high school
                            and fit himself to become a teacher, he passed the
                            next four years in a hard struggle with poverty and
                            in an earnest effort to secure an education,
                            studying for a short time in the Geauga Seminary
                            atChester, Ohio.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="faq-btn text-center mt-50">
                  <a href="faq.html" class="theme_btn faq_btn">
                    Add Your Questions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- faq-area end --> */}
        {/* <!-- subscribe-area start --> */}
        <SubscribeForm />
        {/* <!-- subscribe-area end --> */}
      </main>
    </Layout>
  );
};

export default FAQ;
