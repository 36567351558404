import React from "react";
import { useNavigate } from "react-router-dom";

const MaterialVideos = ({ vidNames }) => {
  const navigate = useNavigate();
  const navigateToPlayer = (index, id) => {
    navigate(`/player`, {
      state: { index: index, vidNames: vidNames },
    });
  };
  return (
    <main>
      {/* <!--page-title-area start--> */}
      <section className="video-card-area pt-150 pb-120 pt-md-100 pb-md-70 pt-xs-100 pb-xs-70">
        <div className="container vid-head">
          {vidNames?.length &&
            vidNames?.map((vid, index) => (
              <div className="vid-2 row">
                {" "}
                <div className="col-xl-3 col-lg-3">
                  <a onClick={() => navigateToPlayer(index, vid._id)}>
                    <img
                      className="card-img-top"
                      src={vid?.path}
                      alt="Card image cap"
                    />
                  </a>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="intro">
                    <a onClick={() => navigateToPlayer(index, vid._id)}>
                      <h2 className="courses-title mb-30">{vid?.name}</h2>
                      <h5>{vid?.description}</h5>
                    </a>
                  </div>
                </div>
                {/* </Link> */}
              </div>
            ))}
        </div>
      </section>
    </main>
  );
};

export default MaterialVideos;
