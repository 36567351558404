import { Row, Col } from 'antd'
const Overview = () => {
    return (
        <div className="overview-container container">
            <div className="overview-heading">
                <h2>Education that gets you industry-ready</h2>
            </div>
            <div className="overview-text">
                <p>College bookish knowledge doesn’t prepare you for real-world and modern careers. EDME, co-creates programs with top industry professionals who can help you get years ahead in your career.</p>
            </div>
            <div className="overview-content">
                <Row gutter={[20, 20]} justify='space-between'>
                    <Col xs={24} md={12} lg={8} xl={6}>
                        <div className="overview-item">
                            <h3>10K+</h3>
                            <h6>Total Students</h6>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={8} xl={6}>
                        <div className="overview-item border-left">
                            <h3>25+</h3>
                            <h6>Programs</h6>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={8} xl={6}>
                        <div className="overview-item border-left">
                            <h3>9/10</h3>
                            <h6>Average Rating</h6>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={8} xl={6}>
                        <div className="overview-item border-left">
                            <h3>150k+</h3>
                            <h6>Total Live Hours</h6>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )
}
export default Overview;