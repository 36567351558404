import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SubscribeForm from "../../components/SubscribeForm";
import Layout from "../../layout/Layout";

const Instructors = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <main>
        {/* <!--page-title-area start--> */}
        <section
          className="page-title-area d-flex align-items-end "
          style={{ backgroundImage: " url('assets/img/page-title-bg/01.jpg')" }}
        >
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="page-title-wrapper mb-50">
                  <h1 className="page-title mb-25">Instructor</h1>
                  <div className="breadcrumb-list">
                    <ul className="breadcrumb">
                      <li>
                        <a href="index.html">Home - Pages -</a>
                      </li>
                      <li>
                        <a href="#">Instructor</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--page-title-area end--> */}
        {/* <!--great-deal-area start--> */}
        <section className="great-deal-area pt-150 pb-90 pt-md-100 pb-md-40 pt-xs-100 pb-xs-40">
          <div className="container">
            <div className="row justify-content-lg-center justify-content-start">
              <div className="col-xl-3 col-lg-8">
                <div className="deal-box mb-30 text-center text-xl-start">
                  <h2 className="mb-20">
                    <b>Great</b> Deals For You
                  </h2>
                  <p>
                    There are many variations of passa of Lorem Ipsum available,
                    but the majority have suffered.
                  </p>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="deal-active owl-carousel mb-30">
                  <div className="single-item">
                    <div className="single-box mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/puzzle.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Learn New Skills</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box2 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/manager.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Expert Trainers</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box3 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/notepad.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Free Trial Lesson</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/puzzle.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Learn New Skills</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box2 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/manager.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Expert Trainers</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--great-deal-area end--> */}
        {/* <!-- course-instructor start --> */}
        <section className="course-instructor nav-style-two pt-150 pb-120 pt-md-95 pb-md-70 pt-xs-95 pb-xs-70">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center mb-60">
                  <h5 className="bottom-line mb-25">Our Instructor</h5>
                  <h2 className="mb-25">Explore Experienced Instructor</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="z-instructors text-center mb-30">
                  <div className="z-instructors__thumb mb-15">
                    <img src="assets/img/instructor/01.jpg" alt="" />
                    <div className="social-media">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <div className="z-instructors__content">
                    <h4 className="sub-title mb-10">
                      <Link to="/instructors/3">John Zlathan</Link>
                    </h4>
                    <p>Software Development</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="z-instructors text-center mb-30">
                  <div className="z-instructors__thumb mb-15">
                    <img src="assets/img/instructor/02.jpg" alt="" />
                    <div className="social-media">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <div className="z-instructors__content">
                    <h4 className="sub-title mb-10">
                      <Link to="/instructors/22">Mally Yan</Link>
                    </h4>
                    <p>UI/UX Designer</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="z-instructors text-center mb-30">
                  <div className="z-instructors__thumb mb-15">
                    <img src="assets/img/instructor/03.jpg" alt="" />
                    <div className="social-media">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <div className="z-instructors__content">
                    <h4 className="sub-title mb-10">
                      <Link to="/instructors/12">Mesud Lamb</Link>
                    </h4>
                    <p>Programmer</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="z-instructors text-center mb-30">
                  <div className="z-instructors__thumb mb-15">
                    <img src="assets/img/instructor/04.jpg" alt="" />
                    <div className="social-media">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <div className="z-instructors__content">
                    <h4 className="sub-title mb-10">
                      <Link to="/instructors/3">Havana Lyon</Link>
                    </h4>
                    <p>Digital Marketing</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="z-instructors text-center mb-30">
                  <div className="z-instructors__thumb mb-15">
                    <img src="assets/img/instructor/03.jpg" alt="" />
                    <div className="social-media">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <div className="z-instructors__content">
                    <h4 className="sub-title mb-10">
                      <Link to="/instructors/3">Lebron Jain</Link>
                    </h4>
                    <p>IT Specialist</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="z-instructors text-center mb-30">
                  <div className="z-instructors__thumb mb-15">
                    <img src="assets/img/instructor/01.jpg" alt="" />
                    <div className="social-media">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <div className="z-instructors__content">
                    <h4 className="sub-title mb-10">
                      <Link to="/instructors/3">Ariana Cany</Link>
                    </h4>
                    <p>UArt Specialist</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="z-instructors text-center mb-30">
                  <div className="z-instructors__thumb mb-15">
                    <img src="assets/img/instructor/04.jpg" alt="" />
                    <div className="social-media">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <div className="z-instructors__content">
                    <h4 className="sub-title mb-10">
                      <Link to="/instructors/3">George Emily</Link>
                    </h4>
                    <p>HR Manager</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="z-instructors text-center mb-30">
                  <div className="z-instructors__thumb mb-15">
                    <img src="assets/img/instructor/02.jpg" alt="" />
                    <div className="social-media">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <div className="z-instructors__content">
                    <h4 className="sub-title mb-10">
                      <Link to="/instructors/3">Chris Jordan</Link>
                    </h4>
                    <p>Dance Specialist</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- course-instructor end --> */}
        {/* <!--what-loking-for start--> */}
        <section className="what-looking-for pos-rel gradient-bg pt-145 pb-130 pt-md-95 pb-md-80 pt-xs-95 pb-xs-80">
          <div className="what-blur-shape-one"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center mb-55">
                  <h5 className="bottom-line mb-25">Teachers & Students</h5>
                  <h2>What you Looking For?</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="what-box text-center mb-3">
                  <div className="what-box__icon mb-30">
                    <img src="assets/img/icon/phone-operator.svg" alt="" />
                  </div>
                  <h3>Do you want to teach here?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    di nonumy eirmod tempor invidunt ut labore et dolore magn
                    aliq erat.
                  </p>
                  <a href="contact.html" className="theme_btn border_btn">
                    Register Now
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="what-box text-center mb-3">
                  <div className="what-box__icon mb-30">
                    <img src="assets/img/icon/graduate.svg" alt="" />
                  </div>
                  <h3>Do you want to learn here?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    di nonumy eirmod tempor invidunt ut labore et dolore magn
                    aliq erat.
                  </p>
                  <a
                    href="contact.html"
                    className="theme_btn border_btn active"
                  >
                    Register Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--what-loking-for end--> */}
        {/* <!-- subscribe-area start --> */}
        <SubscribeForm />
        {/* <!-- subscribe-area end --> */}
      </main>
    </Layout>
  );
};

export default Instructors;
