import React from "react";
import { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import {
  GetCourses,
  getCategories,
  getCourseByCategory,
} from "../../services/courses";
import CourseCard from "../../components/CourseCard";
import loadjs from "loadjs";
import SubscribeForm from "../../components/SubscribeForm";
import { Link } from "react-router-dom";

const Categories = () => {
  const [courses, setCourses] = useState(null);
  const [categories, setCategories] = useState(null);

  const fetchCategories = async () => {
    let data = await getCategories();
    setCategories(data);
  };
  const fetchCourses = async () => {
    let data = await GetCourses();
    setCourses(data);
  };
  const handleFilter = async (id) => {
    let data = await getCourseByCategory(id);
    setCourses(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCourses();
    fetchCategories();
  }, []);

  // loadjs
  // useEffect(() => {
  //   loadjs([
  //     "/assets/js/vendor/modernizr-3.5.0.min.js",
  //     "/assets/js/vendor/jquery-2.2.4.min.js",
  //     "/assets/js/main.js",
  //     "/assets/js/popper.min.js",
  //     "/assets/js/bootstrap.min.js",
  //     "/assets/js/metisMenu.min.js",
  //     "/assets/js/owl.carousel.min.js",
  //     "/assets/js/isotope.pkgd.min.js",
  //     "/assets/js/slick.min.js",
  //     "/assets/js/jquery.nice-select.js",
  //     "/assets/js/ajax-form.js",
  //     "/assets/js/wow.min.js",
  //     "/assets/js/jquery.counterup.min.js",
  //     "/assets/js/waypoints.min.js",
  //     "/assets/js/jquery.scrollUp.min.js",
  //     "/assets/js/imagesloaded.pkgd.min.js",
  //     "/assets/js/jquery.magnific-popup.min.js",
  //     "/assets/js/jquery.easypiechart.js",
  //     "/assets/js/plugins.js",
  //   ],() => {console.log('loadjs loaded')});
  // }, []);
  return (
    <Layout>
      {/* <div id="preloader">
        <div className="preloader">
          <span></span>
          <span></span>
        </div>
      </div> */}
      <main>
        {/* <!--page-title-area start--> */}
        <section
          className="page-title-area d-flex align-items-end "
          style={{ backgroundImage: "url('assets/img/page-title-bg/01.jpg')" }}
        >
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="page-title-wrapper mb-50">
                  <h1 className="page-title mb-25">Courses</h1>
                  <div className="breadcrumb-list">
                    <ul className="breadcrumb">
                      <li>
                        <a href="/">Home - </a>
                      </li>
                      <li>
                        <a href="#">Courses</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--page-title-area end--> */}
        {/* <!--great-deal-area start--> */}
        {/* <section className="great-deal-area pt-150 pb-90 pt-md-90 pb-md-40 pt-xs-100 pb-xs-40">
          <div className="container">
            <div className="row justify-content-lg-center justify-content-start">
              <div className="col-xl-3 col-lg-8">
                <div className="deal-box mb-30 text-center text-xl-start">
                  <h2 className="mb-20">
                    <b>Great</b> Deals For You
                  </h2>
                  <p>
                    There are many variations of passa of Lorem Ipsum available,
                    but the majority have suffered.
                  </p>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="deal-active owl-carousel mb-30">
                  <div className="single-item">
                    <div className="single-box mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/puzzle.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Learn New Skills</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box2 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/manager.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Expert Trainers</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box3 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/notepad.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Free Trial Lesson</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/puzzle.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Learn New Skills</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="single-box s-box2 mb-30">
                      <div className="single-box__icon mb-25">
                        <img src="assets/img/icon/manager.svg" alt="" />
                      </div>
                      <h4 className="sub-title mb-20">Expert Trainers</h4>
                      <p>
                        There are many variations of pas of Lorm Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!--great-deal-area end--> */}
        {/* <!-- feature-course start --> */}
        <section className="feature-course pt-150 pb-130 pt-md-95 pb-md-80 pt-xs-95 pb-xs-80">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="section-title text-center mb-30">
                  <h5 className="bottom-line mb-25">Featured Courses</h5>
                  <h2>Explore our Popular Courses</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-12 text-center">
                <div className="portfolio-menu mb-60">
                  <button
                    className="gf_btn active"
                    onClick={() => handleFilter("all")}
                  >
                    All
                  </button>
                  |
                  {categories?.map((category, index) => (
                    <button
                      className="gf_btn"
                      data-filter={`.${category?.name}`}
                      onClick={() => handleFilter(category?._id)}
                    >
                      {category?.name}
                    </button>
                  ))}
                  {/* <button className="gf_btn" data-filter=".cat1">
                    Career
                  </button>
                  <button className="gf_btn" data-filter=".cat2">
                    Development
                  </button>
                  <button className="gf_btn" data-filter=".cat3">
                    Business
                  </button>
                  <button className="gf_btn" data-filter=".cat4">
                    science
                  </button>
                  <button className="gf_btn" data-filter=".cat5">
                    Design
                  </button> */}
                </div>
              </div>
            </div>
            {!courses ? (
              <div
                className="d-flex justify-content-center align-items-center mb-40"
                style={{
                  minHeight: "100%",
                }}
              >
                <div
                  className="spinner-border "
                  role="status"
                  style={{
                    minHeight: "100%",
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="row"
                style={{
                  // minHeight: "100%",
                  height: "100% !important",
                }}
              >
                {courses?.length > 0 ? (
                  courses?.map((course, index) => (
                    <CourseCard role="public" course={course} />
                  ))
                ) : (
                  <div
                    className="col-xxl-12 mt-20 text-center mb-20"
                    style={{
                      minHeight: "100%",
                    }}
                  >
                    <h2>No Courses Found</h2>
                  </div>
                )}
              </div>
            )}
            <div className="row">
              <div className="col-xxl-12 mt-20 text-center mb-20">
                <Link to="/" className="theme_btn">
                  Explore More
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- feature-course end --> */}
        {/* <!--what-loking-for start--> */}
        {!localStorage.getItem("token") && (
          <section className="what-looking-for pos-rel gradient-bg pt-145 pb-130 pt-md-95 pb-md-80 pt-xs-95 pb-xs-80">
            <div className="what-blur-shape-one"></div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="section-title text-center mb-55">
                    <h5 className="bottom-line mb-25">Teachers & Students</h5>
                    <h2>What you Looking For?</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="what-box text-center mb-3">
                    <div className="what-box__icon mb-30">
                      <img src="assets/img/icon/phone-operator.svg" alt="" />
                    </div>
                    <h3>Do you want to teach here?</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed di nonumy eirmod tempor invidunt ut labore et dolore
                      magn aliq erat.
                    </p>
                    <Link to="/mentor" className="theme_btn border_btn">
                      Register Now
                    </Link>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="what-box text-center mb-3">
                    <div className="what-box__icon mb-30">
                      <img src="assets/img/icon/graduate.svg" alt="" />
                    </div>
                    <h3>Do you want to learn here?</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed di nonumy eirmod tempor invidunt ut labore et dolore
                      magn aliq erat.
                    </p>
                    <Link to="/auth" className="theme_btn border_btn active">
                      Register Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* <!--what-loking-for end--> */}
        {/* <!-- subscribe-area start --> */}
        <SubscribeForm />
        {/* <!-- subscribe-area end --> */}
      </main>
    </Layout>
  );
};

export default Categories;
