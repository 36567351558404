import React from 'react'

const SubscribeForm = () => {
  return (
    <section className="subscribe-area border-bot  pb-50  pt-xs-90">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8">
          <div className="subscribe-wrapper text-center mb-30">
            <h2>Subscribe our Newsletter & Get every updates.</h2>
            <div className="subscribe-form-box pos-rel">
              <form className="subscribe-form">
                <input type="text" placeholder="Write Your E-mail" />
              </form>
              <button className="sub_btn">Subscribe Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default SubscribeForm