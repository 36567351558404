import axios from "axios";
import { url } from ".";

export const Login = async (payload) => {
  console.log(payload);
  const { data } = await axios.post(`${url}/user/login`, payload);
  axios.defaults.headers.Authorization = `Bearer ${data?.token}`
  return data;
};
export const Register = async (payload) => {
  console.log(payload);
  const { data } = await axios.post(`${url}/user/create`, payload);
  return data;
};
