import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import loadjs from "loadjs";

const Navbar = () => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  const onLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  const studentPortal = () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("name");

    // http://localhost:3000/login?token=
    // window.open("https://edme-student.web.app/login?token="+token, "_blank");
    window.open(
      "http://localhost:3000/login?token=" + token + "&name=" + user,
      "_blank"
    );
  };

  console.log(window.innerWidth);

  return (
    <div>
      <header>
        <div
          id="theme-menu-one"
          class="main-header-area pl-100 pr-100 pt-20 pb-15"
        >
          <div class="container">
            <div
              class="row align-items-center"
              style={{ justifyContent: "space-between" }}
            >
              <div class="col-xl-2 col-lg-2 col-5">
                {/* <div class="col-xs-2 col-lg-2 col-sm-2 col-5"> */}
                <div class="logo">
                  <Link to="/">
                    <img src="assets/img/logo/header_logo_one.png" alt="" />
                  </Link>
                </div>
              </div>
              {/* class="col-xl-6 col-lg-6 d-none d-lg-block" */}

              <div
                className={
                  localStorage.getItem("token")
                    ? "col-xl-7 col-lg-7 d-none d-lg-block"
                    : "col-xl-6 col-lg-6 d-none d-lg-block"
                }
              >
                {/* <div class="col-xs-8 col-lg-6 col-sm-8"> */}
                {/* <nav class="main-menu navbar navbar-expand-lg justify-content-center"> */}
                <nav class="main-menu navbar navbar-expand-lg justify-content-center">
                  <div class="nav-container">
                    <div
                      class="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul class="navbar-nav">
                        {/* <li class="nav-item dropdown mega-menu">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            All Categories
                          </a>
                          <ul
                            class="dropdown-menu submenu mega-menu__sub-menu-box"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <a href="index">
                                <span>
                                  <img src="assets/img/icon/icon7.svg" alt="" />
                                </span>{" "}
                                Business
                              </a>
                            </li>
                            <li>
                              <a href="index-2">
                                <span>
                                  <img src="assets/img/icon/icon8.svg" alt="" />
                                </span>{" "}
                                Technology
                              </a>
                            </li>
                            <li>
                              <a href="index">
                                <span>
                                  <img src="assets/img/icon/icon9.svg" alt="" />
                                </span>{" "}
                                Development
                              </a>
                            </li>
                            <li>
                              <a href="index-2">
                                <span>
                                  <img
                                    src="assets/img/icon/icon10.svg"
                                    alt=""
                                  />
                                </span>{" "}
                                Photography
                              </a>
                            </li>
                            <li>
                              <a href="index">
                                <span>
                                  <img
                                    src="assets/img/icon/icon11.svg"
                                    alt=""
                                  />
                                </span>{" "}
                                Digital Marketing
                              </a>
                            </li>
                            <li>
                              <a href="index-2">
                                <span>
                                  <img
                                    src="assets/img/icon/icon12.svg"
                                    alt=""
                                  />
                                </span>{" "}
                                Programming
                              </a>
                            </li>
                            <li>
                              <a href="index">
                                <span>
                                  <img
                                    src="assets/img/icon/icon14.svg"
                                    alt=""
                                  />
                                </span>{" "}
                                Videograpgy
                              </a>
                            </li>
                            <li>
                              <a href="index-2">
                                <span>
                                  <img
                                    src="assets/img/icon/icon13.svg"
                                    alt=""
                                  />
                                </span>{" "}
                                Illustration
                              </a>
                            </li>
                          </ul>
                        </li> */}
                        <li class="nav-item">
                          <Link
                            class="nav-link"
                            to={"/"}
                            id="navbarDropdown5"
                            role="button"
                            aria-expanded="false"
                          >
                            Home
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link
                            class="nav-link"
                            to={"/categories"}
                            id="navbarDropdown5"
                            role="button"
                            aria-expanded="false"
                          >
                            Courses
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link
                            class="nav-link"
                            to={"/about"}
                            id="navbarDropdown5"
                            role="button"
                            aria-expanded="false"
                          >
                            About
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link
                            class="nav-link"
                            to={"/contact"}
                            id="navbarDropdown5"
                            role="button"
                            aria-expanded="false"
                          >
                            Contact
                          </Link>
                        </li>
                        {!localStorage.getItem("token") && (
                          <li class="nav-item dropdown">
                            <a
                              class="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown3"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Login
                            </a>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="navbarDropdown3"
                            >
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="http://edme.media:83/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Mentor Login
                                </a>
                              </li>
                              <li>
                                <Link class="dropdown-item" to="/auth">
                                  Student Login
                                </Link>
                              </li>
                            </ul>
                          </li>
                        )}
                        {localStorage.getItem("token") && (
                          <li class="nav-item">
                            <Link
                              class="nav-link"
                              to={"/learning"}
                              id="navbarDropdown5"
                              role="button"
                              aria-expanded="false"
                            >
                              My Learning
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              {/* class="col-xl-4 col-lg-4 col-7" */}
              <div
                className={
                  localStorage.getItem("token")
                    ? "col-xl-3 col-lg-3 col-7"
                    : `${width < 770 ? "col-4" : "d-none"}`
                }
              >
                {/* <div class="col-xs-8 col-lg-4 col-sm-8"> */}
                <div class=" right-nav d-flex align-items-center justify-content-end">
                  {/* <div class="right-btn mr-25 mr-xs-15"> */}
                  <ul class="logout d-flex align-items-center ">
                    {localStorage.getItem("token") && (
                      <>
                        <li onClick={onLogout} className="theme_btn free_btn">
                          Logout
                        </li>

                        <li>
                          <a
                            class="sign-in ml-20"
                            href="http://edme.media:82/login"
                            target="_blank"
                          >
                            <img src="assets/img/icon/user.svg" alt="" />
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                  {/* </div> */}
                  <div class="hamburger-menu d-md-inline-block d-lg-none text-right">
                    <a href="#">
                      <i class="far fa-bars"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <!-- slide-bar start --> */}
      <aside class="slide-bar">
        <div class="close-mobile-menu">
          <a href="">
            <i class="fas fa-times"></i>
          </a>
        </div>

        {/* <!-- offset-sidebar start --> */}
        <div class="offset-sidebar">
          <div class="offset-widget offset-logo mb-30">
            <a href="index">
              <img src="assets/img/logo/header_logo_one.png" alt="logo" />
            </a>
          </div>
          <div class="offset-widget mb-40">
            <div class="info-widget">
              <h4 class="offset-title mb-20">About Us</h4>
              <p class="mb-30">
                But I must explain to you how all this mistaken idea of
                denouncing pleasure and praising pain was born and will give you
                a complete account of the system and expound the actual
                teachings of the great explore
              </p>
              <Link class="theme_btn theme_btn_bg" to="/contact">
                Contact Us
              </Link>
            </div>
          </div>
          <div class="offset-widget mb-30 pr-10">
            <div class="info-widget info-widget2">
              <h4 class="offset-title mb-20">Contact Info</h4>
              <p>
                {" "}
                <i class="fal fa-address-book"></i> 23/A, Miranda City Likaoli
                Prikano, Dope
              </p>
              <p>
                {" "}
                <i class="fal fa-phone"></i> +0989 7876 9865 9{" "}
              </p>
              <p>
                {" "}
                <i class="fal fa-envelope-open"></i> info@example.com{" "}
              </p>
            </div>
          </div>
        </div>
        {/* <!-- offset-sidebar end --> */}

        {/* <!-- side-mobile-menu start --> */}
        <nav class="side-mobile-menu">
          <ul id="mobile-menu-active">
            {/* <li class="has-dropdown">
              <a href="index">Home</a>
              <ul class="sub-menu">
                <li>
                  <a href="index">Home Style 1</a>
                </li>
                <li>
                  <a href="index-2">Home Style 2</a>
                </li>
                <li>
                  <a href="index-3">Home Style 3</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="about">About</a>
            </li> */}
            {/* <li class="has-dropdown">
              <a href="#">Pages</a>
              <ul class="sub-menu">
                <li>
                  <a href="courses">Course One</a>
                </li>
                <li>
                  <a href="courses-2">Course Two</a>
                </li>
                <li>
                  <a href="course-details">Courses Details</a>
                </li>
                <li>
                  <a href="price">Price</a>
                </li>
                <li>
                  <a href="instructor">Instructor</a>
                </li>
                <li>
                  <a href="instructor-profile">Instructor Profile</a>
                </li>
                <li>
                  <a href="faq">FAQ</a>
                </li>
                <li>
                  <a href="login">login</a>
                </li>
              </ul>
            </li>
            <li class="has-dropdown">
              <a href="#">Blogs</a>
              <ul class="sub-menu">
                <li>
                  <a href="blog">Blog Grid</a>
                </li>
                <li>
                  <a href="blog-details">Blog Details</a>
                </li>
              </ul>
            </li> */}
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/categories">All Courses</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/faq">FAQs</Link>
            </li>
            <li>
              <Link to="/contact">Contacts Us</Link>
            </li>
            {localStorage.getItem("token") ? (
              <>
                {/* <li onClick={studentPortal} className="std">
                  Student Portal
                </li> */}
                <li onClick={onLogout} className="lgt">
                  Logout
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to={"/auth"}>Student Login</Link>
                </li>
                <li>
                  <a
                    href="http://edme.media:83/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mentor Login
                  </a>
                </li>
              </>
            )}
          </ul>
        </nav>
        {/* <!-- side-mobile-menu end --> */}
      </aside>
      <div class="body-overlay"></div>
      {/* <!-- slide-bar end --> */}
    </div>
  );
};

export default Navbar;
