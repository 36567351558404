import React from "react";
// import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { CheckReserveCourse } from "../services/courses";

const CourseCard = ({ role1, role, course }) => {
  // const [reserved, setReserved] = useState(false);
  // const checkIfReserved = async () => {
  //   let data = await CheckReserveCourse(course?._id);
  //   console.log(data)
  //   setReserved(data);
  //   console.log("reserved ",course?._id," ",reserved)
  // };
  // useEffect(() => {
  //   // window.scrollTo(0, 0);
  //   checkIfReserved();
  // }, []);
  // console.log("role ", role);
  return (
    <Link
      to={
        role == "public"
          ? `/course-details/${course?._id}`
          : role == "student"
          ? `/material/${course?._id}`
          : null
      }
      // to= {`/material/${course?._id}`}
      // to={`/course-details/${course?._id}`}
      className="col-lg-4 col-md-6 grid-item"
    >
      <div className="z-gallery mb-30">
        <div className="z-gallery__thumb mb-20">
          <img
            className="img-fluid"
            style={{ height: "200px", objectFit: "cover" }}
            src={course?.coverImage}
            alt=""
          />

          <div className="feedback-tag">4.8(256)</div>
          {/* <div className="heart-icon">
            <i className="fas fa-heart"></i>
          </div> */}
        </div>
        <div className="z-gallery__content">
          <div className="course__tag mb-15">
            <span>Science</span>
            <span>Lab</span>
            <a className="f-right" href="instructor-details">
              <img
                className="rounded-circle"
                src="assets/img/course/in1.png"
                alt=""
              />
            </a>
          </div>
          <h4 className="sub-title mb-20">
            <Link to={`/material/${course?._id}`}>
              {/* <Link to={`/course-details/${course?._id}`}> */}
              {course?.title}
            </Link>
          </h4>
          <div className="course__meta">
            <span>
              <img
                className="icon"
                src="assets/img/icon/time.svg"
                alt="course-meta"
              />{" "}
              {course?.duration}
            </span>
            <span>
              <img
                className="icon"
                src="assets/img/icon/bar-chart.svg"
                alt="course-meta"
              />{" "}
              Higher
            </span>
            <span>
              <img
                className="icon"
                src="assets/img/icon/user.svg"
                alt="course-meta"
              />{" "}
              6395+
            </span>
            <span>${course?.fees}</span>
          </div>
          {role1 == "wishlist" && (
            <div style={{ marginTop: "20px" }} class="courses-tag-btn">
              <p>Remove from Wishlist</p>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
