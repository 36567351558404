import React from "react";
import { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import {
  GetEnrolledCourses,
  getWishlist,
  getAllCourseQuiz,
} from "../services/courses";
import CourseCard from "../components/CourseCard";
import loadjs from "loadjs";
import SubscribeForm from "../components/SubscribeForm";
import { useParams, useNavigate } from "react-router-dom";
import MaterialQuiz from "../components/material-quiz";
import { Link } from "react-router-dom";

const Learning = () => {
  const [courses, setCourses] = useState(null);
  const [wishlist, setWishlist] = useState(null);
  const [quizzes, setQuizzes] = useState(null);
  const [showCourses, setShowCourses] = useState(true);
  const [showWishlist, setShowWishlist] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [active, setActive] = useState("course");
  const param = useParams();
  const navigate = useNavigate();

  const fetchCourses = async () => {
    let data = await GetEnrolledCourses();
    let lst = await getWishlist();
    let qz = await getAllCourseQuiz();
    setQuizzes(qz);
    setCourses(data);
    setWishlist(lst);
    // console.log("wishlist ", lst);
  };
  useEffect(() => {
    // console.log(param.id)
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    window.scrollTo(0, 0);
    fetchCourses();
  }, []);
  // const changeComponentToWishlist = async () => {
  //   setShowCourses(false);
  //   setShowWishlist(true);
  //   setShowQuiz(false);
  // };
  // const changeComponentToVideos = async () => {
  //   setShowCourses(true);
  //   setShowWishlist(false);
  //   setShowQuiz(false);
  // };
  // const changeComponentToQuiz = async () => {
  //   setShowCourses(false);
  //   setShowWishlist(false);
  //   setShowQuiz(true);
  // };
  return (
    <Layout>
      <main>
        <section
          className="page-title-area d-flex align-items-end "
          style={{ backgroundImage: "url('assets/img/page-title-bg/01.jpg')" }}
        >
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="page-title-wrapper mb-50">
                  <h1 className="page-title mb-25">My Learning</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--great-deal-area end--> */}
        {/* <!-- feature-course start --> */}
        <section className="feature-course pt-150 pb-130 pt-md-95 pb-md-80 pt-xs-95 pb-xs-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 text-center">
                <div className="portfolio-menu mb-60">
                  <button
                    onClick={() => {
                      setActive("course");
                    }}
                    className={`${
                      active === "course" && "active"
                    } enr-btn gf_btn `}
                    data-filter="*"
                  >
                    Enrolled Courses
                  </button>
                  <button
                    onClick={() => {
                      setActive("wishlist");
                    }}
                    className={`${
                      active === "wishlist" && "active"
                    } wl-btn gf_btn`}
                    data-filter=".cat1"
                  >
                    WishList
                  </button>
                  <button
                    onClick={() => {
                      setActive("quiz");
                    }}
                    className={`${active === "quiz" && "active"} qz-btn gf_btn`}
                    data-filter=".cat2"
                  >
                    Quizzes
                  </button>
                  {/* <button className="gf_btn" data-filter=".cat3">
                    Business
                  </button>
                  <button className="gf_btn" data-filter=".cat4">
                    science
                  </button>
                  <button className="gf_btn" data-filter=".cat5">
                    Design
                  </button> */}
                </div>
              </div>
            </div>
            {active === "course" ? (
              !courses?.length ? (
                <div className="d-flex justify-content-center align-items-center mb-40">
                  <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="grid row">
                  {courses?.map((course, index) => (
                    <CourseCard role="student" course={course?.course} />
                  ))}
                </div>
              )
            ) : active === "wishlist" ? (
              !wishlist?.length ? (
                <div className="d-flex justify-content-center align-items-center mb-40">
                  <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="grid row">
                  {wishlist?.map((lst, index) => (
                    <CourseCard
                      role1="wishlist"
                      role="public"
                      course={lst?.course}
                    />
                  ))}
                </div>
              )
            ) : active === "quiz" ? (
              !quizzes?.length ? (
                <div className="d-flex justify-content-center align-items-center mb-40">
                  <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <section className="schedule-card-area pt-150 pb-120 pt-md-100 pb-md-70 pt-xs-100 pb-xs-70">
                  <div className="container">
                    {!quizzes?.length && (
                      <div className="d-flex justify-content-center align-items-center mb-40">
                        <div className="spinner-border " role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}

                    {quizzes?.length &&
                      quizzes?.map((quiz, index) => (
                        <MaterialQuiz quizzes={quiz} />
                        // <div className="meetingsection row">
                        //   <h2 className="courses-title mb-30">{quiz?.title}</h2>
                        //   <h5>
                        //     Passing <b>60%</b>
                        //   </h5>
                        //   <div className="meetinactions">
                        //     <Link to={"/quiz/" + quiz._id}>
                        //       <button className="gf_btn">Start</button>
                        //     </Link>
                        //   </div>
                        // </div>
                      ))}
                  </div>
                </section>
              )
            ) : null}
            {/* 
            {showWishlist?
              (!wishlist?.length ? (
                <div className="d-flex justify-content-center align-items-center mb-40">
                  <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="grid row">
                  {wishlist?.map((lst, index) => (
                    <CourseCard course={lst?.course} />
                  ))}
                </div>
              )):null} */}

            <div className="row">
              <div className="col-xxl-12 mt-20 text-center mb-20">
                <a href="courses.html" className="theme_btn">
                  Explore More
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- feature-course end --> */}
        {/* <!-- subscribe-area start --> */}
        <SubscribeForm />
        {/* <!-- subscribe-area end --> */}
      </main>
    </Layout>
  );
};

export default Learning;
